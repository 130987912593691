@import '~assets/stylesheets/abstracts/variables';

.openMenuButton {
  button {
    border-radius: 45px;
  }

  .icon {
    color: $white;
    width: 15px;
    height: 12px;
    margin-right: 5px;
  }
}

.sectionList {
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  background-color: $white;
  width: 300px;

  .sectionListHeader {
    background-color: $green;
    color: $white;
    padding: 5px 10px;

    .closeButton {
      font-size: 16px;
      color: $white;
      float: right;
    }
  }

  .sectionListBody {
    padding: 5px 8px;
    line-height: 150%;
  }

  .sectionGroupTitle {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
