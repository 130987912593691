@import '~common/colors';

.topRow {
  display: flex;
  margin: 15px 0;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
}

.pagingButtons {
  display: flex;
  flex-grow: 1;

  .classroomSelect {
    margin-left: 20px;
  }
}

.gradingProgress {
  right: 0;
  top: 10px;
  padding: 15px;
  border: 1px solid $med-grey;
  border-radius: 4px;

  div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.green { color: $green; }
.orange { color: $orange; }
.red { color: $red; }

.titleContainer {
  margin: 0 0 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    margin-right: 15px;
  }
}

.toggleGroupsContainer {
  display: flex;
  margin: 20px 0 0 5px;

  .groupContainer {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
}


.gradingDropdowns {
  display: flex;
  align-items: center;
  gap: 1rem;
}
