@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.container {
  padding: 0 5px;
  margin: 0 -2px 40px -2px;
  border-left: 3px solid $tci-purple;
}

.container:last-of-type {
  margin-bottom: 1rem;
}

.title {
  display: flex;
}

.circleIcon {
  margin-left: -12px;
  margin-right: 10px;
  margin-top: -2px;
  color: $tci-purple;
}

.body {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 540px;
}

.row:last-of-type {
  margin-bottom: 0;
}

.fieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detailList {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  line-height: 20px;

  .detailOption {
    display: flex;
    align-items: center;
    margin-top: 10px;

    input[type='radio'], input[type='checkbox'] {
      margin: 0 5px 0 0;
    }
  }
}

.selectColumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0;
  flex-grow: 1;
}

.label, .spacer {
  width: 120px;
}

.label {
  font-weight: bold;
}

.fieldError {
  color: $red;
  margin: 5px 0 0 0;
}

.sectionField {
  width: 200px;
}

.list {
  list-style: disc;
  margin-left: 25px;
  margin-top: 10px;
}

.timeZone {
  display: flex;
  flex-direction: row-reverse;
  max-width: 540px;
}

.textArea {
  font-family: $font-family-sans-serif;
}

.checkboxField {
  display: flex;
  flex: 1 1 5%;
  margin: .5em 0 0 .5em;

  label {
    align-items: center;
    display: flex;
    flex: 1 1 95%;
    font-weight: normal !important;
    gap: 5px;
  }
}

.scoreField {
  margin-left: -20px;
}
