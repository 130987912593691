@import '~assets/stylesheets/abstracts/variables';

.container {
  display: flex;
  align-items: center;

  .dropdown {
    width: 300px;
    margin-left: 10px;
  }
}
