@import '~common/colors';

.navigationCard {
  display: flex;
  flex-direction: column;
  min-height: 165px;
  width: 100%;
  border-left: 5px solid #3fae2a !important;
  margin: 25px 0;

  .cardHeader {
    margin: 15px 20px 0 20px;
    overflow: hidden;
    padding-bottom: 5px;

    .cardTitle {
      font-weight: 700;
      font-size: 19.6px;
      color: $very-dark-grey;
    }

    hr {
      background-color: #ccc;
    }
  }

  .libraryLinks {
    display: flex;
    gap: 2.5em;

    .comingSoon {
      cursor: default;
    }
  }

  .libraryLink {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 5rem;
    text-align: center;

    &:first-child {
      margin-left: 2em;
    }
    //padding: 0 1em;

    .icon {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      border-radius: 4px;
      padding: .5em;
      position: relative;

      &.comingSoon {
        &::after {
          content: 'Coming Soon';
          position: absolute;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 9px;
          color: #fff;
          white-space: nowrap;
        }
      }

      img {
        width: 100%;
      }

      &.green {
        background-color: #2a8806;
      }

      &.purple {
        background-color: $tci-purple;

        img {
          width: 60%;
        }
      }

      &.orange {
        background-color: #f69c05;

        img {
          width: 70%;
        }
      }

      &.red {
        background-color: #b44539;

        img {
          width: 60%;
        }
      }

      &.blue {
        background-color: #005595;

        img {
          width: 60%;
        }
      }

      &.blueGreen {
        background-color: #247f99;

        img {
          width: 60%;
        }
      }
    }

    p {
      font-weight: bold;
    }
  }
}

.unitProjectLinksWrapper {
  display: flex;
  flex-direction: column;

  .notebookSection {
    display: inherit;
    flex-direction: inherit;
  }

  :not(:first-child) {
    margin-top: 10px;
  }

  b {
    margin-bottom: 10px;
  }

  .link {
    align-items: revert;
    margin: 0 0 10px 45px;
  }

}

.defaultLink {
  align-items: center;
  font-weight: bold;
  margin: 10% 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
