.container {
  padding: 0 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.wrapper {
  align-items: center;
  justify-content: space-between;
}

.input {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 50%;
}

.numberInput {
  width: 45%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  margin-right: 4px;
}
