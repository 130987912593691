.dropdown-icon {
  position: relative;
  top: -1px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 2px;
  cursor: pointer;
  padding: 2px;
  color: #777770;
  font-size: 10px;
}

.filter-container {
  display: inline;
  position: relative;
}

.popup-div {
  position: absolute;
  z-index: 1050;
  top: 22px;
  left: 0;
  width: 200px;
  text-align: left;
  padding: .8em;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 .7em 0 #999;
}
