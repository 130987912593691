@import '~common/colors';

.welcome {
  line-height: 1.2rem;
  width: 80%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.header {
  font-size: 16px;
  font-weight: bolder;
  font-family: Lato, "Times New Roman", serif;
}

.border {
  color: $light-grey;
}

.betaFeature {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .descriptionWrapper {
    display: flex;

    p {
      line-height: 1.2rem;
    }

    button {
      margin: auto;
    }

    .description {
      flex-flow: row;
      width: 60%;
    }
  }
}

.warningIcon {
  margin-right: 0.25rem;
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p { margin: 20px; }
  button { margin: auto; }
}

.previewImage {
  width: 60%;
  max-width: 270px;
}
