.form {
  display: flex;
  flex-flow: column;

  h1 {
    margin-bottom: 10px;
  }

  margin: 20px;
}

.row {
  display: flex;
  flex-direction: column;
  max-width: 540px;
}

.field {
  display: flex;
  align-items: center;

  label {
    display: flex;
    width: 120px;
    font-weight: bold;
  }
}

.select {
  width: 100%;
}

.submitButton {
  align-self: flex-end;
  margin-top: auto;
}

.contentContainer {
  position: absolute;
  left: 70%;

  .content {
    text-align: center;
  }
}
