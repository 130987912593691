.formGroup {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  .formField {
    width: 10rem;

    label {
      font-weight: normal !important;
    }

    .scoreInput {
      width: 20px !important;
      margin-right: 10px;
    }
  }
}

.error {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 0;
  color: red;
}
