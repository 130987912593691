@import '~common/colors';

.button {
  width: 100%;
  border: none;
  font-size: 16px;
  height: 50px;
  border-radius: 0 5px 5px 0;
  text-align: left;
  padding-left: 50px;
  cursor: pointer;
  display: block;
  background-color: $white;
}

.button:hover {
  background: #f7f4fa;
}

.active {
  font-weight: bold;
  background: #f7f4fa;
  color: $tci-purple;
  border-left: 4px solid $tci-purple;
}

.tab_container {
  flex-basis: 30%;
  flex-shrink: 0;
}
