@import '_cards.scss';
@import '../../assets/stylesheets/abstracts/mixins';

.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: $btn-padding-y $btn-padding-x;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  border-radius: $btn-border-radius;
  border: $btn-border-width solid transparent;
  transition: $btn-transition;
  box-shadow: $btn-box-shadow;

  &.rounded-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $btn-border-radius;
    border-bottom-right-radius: $btn-border-radius;
  }

  // Share hover and focus styles
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    box-shadow: none;
  }

  // Add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: $btn-active-box-shadow;

    &:focus,
    &.focus {
      box-shadow: $btn-focus-box-shadow;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

// Color standard buttons
@each $color, $value in $theme-colors {
  .btn--#{$color} {
    @include button-variant($value, $value);
    &:focus,
    &.focus {
      box-shadow: 0 0 3pt 2pt $value !important;
      border-radius: 5px;
    }
  }
}

// Outline buttons
@each $color, $value in $theme-colors {
  .btn--outline-#{$color} {
    @include button-outline-variant($value);
    &:focus,
    &.focus {
      box-shadow: 0 0 3pt 2pt $value !important;
      border-radius: 5px;
    }
  }
}

// Button links
@each $color, $value in $theme-colors {
  .btn--link-#{$color} {
    @include button-link-variant($value);
  }
}

// Sizes
.btn--lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn--sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

// Block buttons
.btn--block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn--block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn--block {
    width: 100%;
  }
}

.btn--card {
  @extend .card;

  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: inherit;
  padding: 20px 35px;

  img {
    margin-right: 15px;
    width: 30px;
  }

  &:hover {
    background-color: $tci-purple;
    color: white;

    &:not(.preserve-color) img { filter: brightness(100); }
  }
}

.slide .hintButton.hideBr + br {
  display: none;
}
