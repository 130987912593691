div.classroomCard {
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;

  img {
    width: 35%;
    margin: 5px auto;
    display: block;
  }
}

div.classroomCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.courseContainer {
  display: flex;
  margin: 50px;
  min-height: 200px;

  .section {
    width: 50%;
  }

  .courseTitle {
    display: block;
  }

  .selectedCard {
    padding: 15px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    width: 70%
  }

  .divider {
    margin: 0 50px;
    width: 5%;
    border-left: 1px solid #CCCCCC;
  }

  img {
    width: 35%;
  }
}

.expandCollapseWrapper {
  color: #eee;
}

.formInput {
  display: block;
  margin-left: 25px;
  padding: 5px;
}

.label {
  vertical-align: middle;

  input[type="radio"] {
    margin-top: -1px;
    margin-right: 10px;
  }
}

.modalFooter {
  position: absolute;
  width: 95%;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #fff;
}
