@import "../variables";

.page {
  position: relative;
  text-align: left;
}

.loading {
  flex-grow: 1;
  padding-top: 15rem;
  text-align: center;
  font-size: 80px;
}

.topPagination {
  position: absolute;
  top: 0;
  right: 0;
}

.container {
  display: flex;
}

.description {
  font-size: medium;
  padding: $padding_medium 0;
}

.cardContainer {
  flex-grow: 1;
  height: fit-content;
  margin-left: $margin_medium;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.paginationWrapper {
  flex-grow: 1;
  display: flex;
  height: fit-content;
  flex-direction: column;
}

.bottomPagination {
  align-self: flex-end;
}

.noResult {
  flex-grow: 1;
}

.noResultTitle {
  margin-top: $margin_xlarge;
  margin-bottom: $margin_large;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.noResultDescription {
  font-size: 16px;
  text-align: center;
}
