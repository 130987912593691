@import '~assets/stylesheets/abstracts/variables';

.field {
  border-radius: 4px;
  border: 1px solid $med-grey;
  font-size: 1em;
  padding: 10px;
}

.spinner {
  font-size: 24px;
}

.notesField {
  height: 100px;
  width: 100%;
  font-family: $font-family-base;

  .notesRowAlignment {
    align-items: normal;
  }
}
