@import '~assets/stylesheets/abstracts/variables';

.dropdown {
  z-index: $zindex-popover;
  position: absolute;
  top: 43px;
  right: 0;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 $light-grey;
}

.dropdown::before {
  position: absolute;
  top: -17px;
  right: 0;
  content: "";
  border: 9px solid transparent;
  border-bottom-color: #f7f7f7;
}

.header {
  padding: 1rem;
  font-size: 20px;
  font-weight: 700;
  color: $black;
  background-color: #fafafa;
  line-height: initial;
  border-bottom: 1px solid lightgrey;
}

.messagesContainer {
  position: relative;
  overflow-y: scroll;
  max-height: 350px;
  color: $dark-grey;
  background: $white;

  &.loading {
    min-height: 350px;
  }
}

.message {
  padding: 1rem;
  font-size: 14px;
  line-height: initial;
  margin: 4px 0;
  color: $very-dark-grey;
  font-style: normal;
  overflow-wrap: break-word;
}

.message:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.helpCenterButton {
  margin-top: 10px;
}
