@import '~common/colors';

.textarea {
  width: 100%;
  min-height: 70px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid $med-grey;
  font-family: Lato, Helvetica, Arial, clean, sans-serif;
  font-size: .875rem;
  line-height: 1rem;
  color: #555;
  display: block;
  background-color: white;
}

.imagesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
}

.questionImage {
  max-width: 100%;
  margin-top: 10px;
}

.invalid {
  .textarea {
    outline: none;
    border: 2px solid $red;
    border-radius: 4px;
  }
}

.invalidStatusMessage {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0 0 0 10px;
  color: $red;
  text-align: left;

  i {
    color: $red;
    margin-right: 15px;
  }
}
