@import "../../colors";
@import "../../variables";

#toast-container-main {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  width: 28rem;
}

#toast-container-main > div {
  position: relative;
  background-color: $white;
  color: $very-dark-grey;
  width: 100%;
  margin-bottom: 10px;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  animation: slide-in 0.5s;
  transition: all 0.3s ease;
  border: 1px solid $light-grey;
  box-shadow: $box_shadow_medium;
  border-radius: $radius_medium;
}

.toastBody {
  padding: $padding_small $padding_small $padding_small $padding_medium;
}

.iconBox {
  color: $white;
  font-weight: bolder;
  flex-shrink: 0;
  width: 3rem;
  border-radius: $radius_medium 0 0 $radius_medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.success {
  background-color: $green;
}

.info {
  background-color: $teal-blue;
}

.warning {
  background-color: $orange;
}

.error {
  background-color: $red;
}

.toastTitle {
  font-weight: bold;
  margin-bottom: $margin_small;
}

@keyframes slide-in {
  from {
    transform: translateX(2rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.closeButton {
  background: none;
  border: none;
  outline: none;
  color: $very-dark-grey;
  font-size: medium;
  font-stretch: expanded;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
