@import '~common/colors';

.input:invalid {
  border-color: inherit;
}

.invalid {
  border-color: $red;
}

.fieldError {
  color: $red;
}
