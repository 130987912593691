@import "../../colors";
@import "../../variables";

.container {
  display: flex;

  li {
    min-width: 1rem;
    min-height: 1rem;
    padding: $padding_small;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: $radius_medium;
    color: $dark-grey;
    background-color: $white;
    cursor: pointer;
  }
}

.active {
  color: $white !important;
  background-color: $tci-purple !important;
}

.disabled {
  display: none !important;
}

.nextPrev {
  font-family: monospace;
  font-size: 20px;
}
