.textInput {
  width: 5%;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: 12px;
}

.label {
  width: 85px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.scoreButton {
  padding: 0;
  font-size: 18px !important;
}

.move_next_to {
  position: relative;
  float: right;
  top: -22px;
  left: -35px;
}
