.previewImage {
  max-width: 50px;
  max-height: 50px;
}

.table {
  td {
    vertical-align: middle;
  }
}

.tableActions {
  display: flex;
  align-items: center;
}
