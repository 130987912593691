@import '~common/colors';

.container {
  width: 200px;
}

.paddingContainer {
  padding: 8px 4px;

  .contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paddingWrapper {
    padding: 8px;
    display: inline-flex;
    align-items: center;
    width: 70%;

    span {
      margin-top: 12px;
    }

    .paddingInner {
      display: flex;
      flex-direction: column;
    }

    label {
      margin-bottom: 4px;
    }
  }

  .paddingInput {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 12px 8px;
    margin-right: 4px;
    width: 60px;
  }

  .btnWrapper {
    width: 30%;

    .submitBtn {
      width: 100%;
      padding: 18px 12px;
      margin-left: -12px;
      margin-top: 14px;
    }
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px;
}

.colorSquare {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;

  &:hover {
    outline: 1px solid #222;
    z-index: 2;
  }

  &.remove {
    &:hover {
      background-color: #ebebeb;
    }
  }
}

.removeIcon {
  text-align: center;
  line-height: 32px;
  height: 24px;
  width: 24px;
  margin: 4px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.inputs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0 20px 20px;
  gap: 12px;
}

.inputContainer {
  grid-column: span 2 / span 2;
  position: relative;

  input {
    width: 100%;
    height: 42px;
    border-radius: 2px;
    border: 1px solid #aaa;

    &:focus {
      outline: none;
      border-color: $tci-purple;
      border-width: 2px;
    }

    &:focus, &:not(:placeholder-shown) {
      + label {
        top: -7px;
        left: -3px;
        background: #fff;
        transform: scale(0.8);
      }
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    color: gray;
    pointer-events: none;
    font-weight: 300;
    cursor: text;
    font-size: 14px;
    padding: 0 4px;
    transition: all 400ms ease-in-out;
  }
}

.borderSubmitWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.submitBtn {
  grid-column: span 1 / span 1;
  color: #0098f7;
  background: none;
  border: none;
  border-radius: 0;
  transition: all 200ms ease-in;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
}

/* Hide increment/decrement arrows  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.opacityContainer {
  position: relative;
}

.opacityContainer:after {
  content: attr(data-value) "%";
  pointer-events: none;
  position: absolute;
  top: 16px;
  left: 4px;
}

.opacityContainer input {
  caret-color: #000000;
  color: #FFF;
}

.actionsButtonWrapper {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;

  .actionsButton {
    padding: 0 8px;
    font-size: 16px;
    opacity: 50%;

    .buttonIcon {
      pointer-events: none;
    }
  }
}

.noEdit {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  color: #FFF;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  user-select: none;
  z-index: 2;
}
