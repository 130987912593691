.thumbnailContainer {
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  overflow-wrap: break-word;

  img {
    max-width: 100px;
    max-height: 100px;
  }
}
