@import '~assets/stylesheets/abstracts/variables';

.inputContainer input {
  margin-right: 0;
  vertical-align: initial;
}

.inputContainer label {
  overflow: initial;
}

.largeField {
  width: 100%;
}

.options {
  margin-left: 120px;
}

.simplifiedOptions {
  margin-left: 100px;
}

.errorMessage {
  color: red;
  margin-left: 100px;
  line-height: 18px;
}

.childOption {
  &:not(:global(.Select-value)) {
    padding-left: 30px !important;
    font-weight: normal;
  }
}

.assignableSelector {
  :global(.italics) { font-style: italic }
}

.checkmark {
  color: $green;
  font-size: 20px;
}
