.unitCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
  flex: 0 0 32%;
  min-height: 17em;
  margin-bottom: 15px;
  position: relative;
}

.unitCard:hover {
  text-decoration: none;
}

.unitImage {
  width: 100%;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.infoWrapper {
  background: hsla(0,0%,100%,.93);
  display: flex;
  align-self: flex-end;
  width: 100%;
  border-radius: 0 0 5px 5px;

  .unitInfo {
    padding: 1em;
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon {
      font-size: 30px;
    }

    .unitTitle:hover {
      text-decoration: underline;
    }
  }
}