@import '~common/colors';

.card {
  composes: card from '../../LessonOverview/Cards/Cards.module.scss';
  align-self: flex-start;
}

.header {
  height: 85px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.thumbnail {
  width: 45px;
  height: 45px;
  margin: auto 20px auto auto;
  float: left;
  position: relative;

  color: $white;
  font-size: 1em;
  line-height: 45px;
  text-align: center;

  border-radius: calc(45px / 2);
  background-color: $green;
}

.banner {
  position: relative;
  top: -12px;
  height: 20px;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
  border-top: 2px solid green;
  border-radius: 3px;
  color: #555555;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 3px;
  z-index: 1;
}

// not using background shorthand so we can inherit an svg image
.tciAvatar {
  background-color: $tci-purple;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
}

.title {
  composes: title from '../../LessonOverview/Cards/Cards.module.scss';
  width: 185px;
  margin: auto auto;
  float: left;
}

.titleLink { composes: titleLink from '../../LessonOverview/Cards/Cards.module.scss'; }
