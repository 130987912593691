@import '~assets/stylesheets/abstracts/variables';

.titleSection {
  justify-content: space-between;
  cursor: pointer;
  color: $tci-purple;
  background-color: $white;
  border: none;
  font-family: $font-family-base;
}

.title {
  font-size: 12px;
}

.arrow {
  font-size: 12px;
  display: inline-block;
  position: relative;
  margin-left: 100px;
  top: -15px;
}

.borderBottom {
  border-bottom: 2px solid $base_grey;
  padding-bottom: 5px;
}

.title-with-toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-item {
  padding: $dropdown-item-padding-y 2.5rem;
}

.headerItems {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionItem {
  padding-left: 0;
}

.listItem {
  list-style: none;
}

.assignmentTitle {
  font-size: 14px;
  font-weight: bold;
}

.location {
  font-size: 11px;
}

tr > th.expandCollapseWrapper {
  color: #eee;
}

.expandCollapseWrapper {
  vertical-align: middle;
}

.noteContainer {
  position: relative;
  width: 500px;
  border: 1px solid $med-grey;
  border-radius: 15px;
  padding: .75rem;
  margin-top: 1rem;
  margin-left: 2.5rem;
  color: $very-dark-grey;

  .notes {
    word-break: break-word;
  }

  .noteBubbleTail {
    position: absolute;
    background-color: #fff;
    top: -15px;
    left: 20px;
    width: 24px;
    height: 24px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.viewedStatus {
  text-align: center;
  color: $blue;
  font-weight: bold;
}

.status {
  font-size: 11px;
  margin-left: 20px;
}

.in_progress {
  color: $blue;
}

.turned_in {
  color: $tci-green;
}

.toggleWrapper {
  display: inline-block;
  margin-right: 10px;

  .studentAssignmentToggle {
    background-color: $white;

    .fa { font-size: 1.5em; }
    .clock { color: $blue; }
    .timesCircle { color: $red; }
    .check { color: $tci-green; }

    &.active, &:hover {
      background-color: $tci-purple;
      color: $white;

      .fa { color: $white; }
    }
  }
}
