@import '~assets/stylesheets/abstracts/variables';

.printButton {
  border: 1px solid $med-grey;
  border-radius: $border-radius;
  padding: 13px 10px 5px 10px;
  text-decoration: none;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;

  &:hover { box-shadow: 0 3px 5px rgba(0, 0, 0, .1); }

  &:active {
    background-color: $light-grey;
    box-shadow: none;
  }
}

.shortcutPrintButton {
  display: flex;

  &:hover {
    box-shadow: 0 0 0 3px rgba(82, 46, 145, .2);
    background-color: rgba(82, 46, 145, .2);
    border-radius: 1px;
  }

  .printIcon {
    width: 24px;
    height: 24px;
    margin: 2px 2px 0 2px;
  }
}
