.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelContainer {
  margin-left: 10px;
}

.colorBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &.transfer {
    background-color: #fc9c31;
  }

  &.return {
    background-color: #d82830;
  }

  &.exchange {
    background-color: #2A8806;
  }
}

.transferRow td:first-child {
  border-left: 2px solid #fc9c31;
}

.returnRow td:first-child {
  border-left: 2px solid #d82830;
}

.exchangeRow td:first-child {
  border-left: 2px solid #2A8806;
}

.wrapContent {
  word-break: break-all;
}
