.label {
  padding: 5px;
}

.selectContainer {
  display: flex;
  align-items: center;

  .select {
    width: 300px;
  }

  .check {
    padding-left: 10px;
    font-size: 20px;
  }
}
