@import '~assets/stylesheets/abstracts/variables';

.tabsContainer {
  .tab {
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;

    &:hover, &.active {
      background: $tci-purple;
      color: white;
    }
  }
}

.inlineEditWithLanguage {
  .editButton {
    padding-left: 10px;
    cursor: pointer;
    color: $tci-purple;
  }

  .editModeContainer {
    .tabsContainer {
      margin: 10px 0;
      font-size: .7em;
    }

    .editModeInput {
      height: 26px;
      border-radius: .25rem;
      font-size: 18px;
      border: 1px solid #ccc;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: 300px;
    }

    i {
      padding-left: 10px;
      cursor: pointer;
    }

    .saveIcon {
      color: $green;
      border: none;
      background: transparent;
      font-size: 100%;

      &:focus {
        outline: none;
      }
    }

    .cancelIcon {
      color: $red;
    }

    .errorMessage {
      color: $red;
      line-height: 18px;
      padding: 5px 0;
    }
  }
}
