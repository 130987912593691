.headerContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.actions {
  display: flex;

  button { padding: .25rem .5rem; }
}
