.close {
  font-size: 1.25rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #555;
  position: absolute;
  top: 7px;
  right: 14px;

  &:hover, &:focus {
    color: #000;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
