@import '~assets/stylesheets/abstracts/variables';

span.icon {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: auto;
  min-width: 24px;
  min-height: 24px;
}

%button-base {
  padding: 2px 3px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.gradesLink {
  @extend %button-base;

  display: block;
  color: #522e91;
  font-size: 24px;
  cursor: pointer;
}

.icon {
  @extend %button-base;

  border: none;
  background: none;

  .share, i.share {
    color: #522e91;
    font-size: 24px;
  }

  .assigned, i.assigned {
    color: $green;
    font-size: 24px;
  }

  .disabled {
    color: #666;
    font-size: 24px;
    cursor: not-allowed;
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;

    img {
      opacity: 1;
      filter: grayscale(100%);
    }
  }

  &:not(:disabled) &:hover {
    box-shadow: 0 0 0 3px rgba(82, 46, 145, .2);
    background-color: rgba(82, 46, 145, .2);
    border-radius: 1px;
  }
}

.lmsShareModal {
  width: 550px;

  .lmsShareModalBody {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      align-items: center;

      .fieldContainer {
        display: flex;
        flex-direction: column;
        width: 70%;

        .textCopyField {
          padding: 8px 10px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 100%;
        }
      }

      .copyButton {
        width: 82px;
        height: 34px;
        margin-right: 10px;
        margin-top: 15px;
      }

      .shareToGoogleButton { margin-right: 60px; }
    }
  }
}

.assignmentTooltip {
  .header {
    font-weight: bold;
    margin-top: 10px;
  }

  hr {
    margin: 5px 0 !important;
    color: #eee;
  }
}

.shareIcon {
  display: flex;
  width: 24px;
  height: 24px;
  margin: 1px 1px -1px 0;
}

.settingsShortcutModal hr {
  margin: 1em 0;
}
