.connectedStudentsTooltip {
  margin: 8px;
  font-size: 14px;
  text-align: left;

  a {
    font-size: 13px;
  }
}

.studentGoogleInfoContainer {
  display: flex;
  height: 1.5em;
  margin-right: 3px;

  img {
    width: 18px;
    margin-left: 3px;
  }
}

.studentsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

