@import '~common/colors';

.caption {
  display: block;
}

.dottedLine {
  border-bottom: 3px dotted $med-grey;
  height: 1px;
  width: 60px;
  margin-bottom: 2%;
  margin-left: -27px;
  margin-right: -27px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #f8f9fb;
  margin-top: -1.25rem;
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  margin-bottom: 1.25rem;
}

.headerImage {
  opacity: .4;
  height: 55px;
  width: 55px;
  margin-bottom: 10px;
}

.headerImageContainer {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  color: #cab3e9;
  width: 130px;
}

.activeImg {
  opacity: 1;
}

.activeCaption {
  color: $tci-purple;
}

.currentStep {
  font-weight: bold;
}
