.progress {
  width: 100px;
  height: 100px;
}

.fill {
  stroke-dasharray: 219.99078369140625;
  stroke-dashoffset: -219.99078369140625;
  transition: stroke-dashoffset .5s;
  stroke: #ff9800;
}

.transform {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 3;
  transform: rotate(90deg) translate(0, -80px);
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
