.mainImage {
  display: block;
  margin: 0 auto 20px;
}

.rosterImageContainer {
  img {
    align-items: center;
    margin: auto;
  }

  .sftpImage {
    height: 50px;
    width: 50px;
  }

  .oneRosterImage {
    height: 35px;
    width: 120px;
  }

  .cleverImage {
    height: 24px;
    width: 100px;
  }
}
