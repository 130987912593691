@import '~assets/stylesheets/abstracts/variables';

.slideShowBuilderWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border: 1px solid $med-grey;
  border-radius: 3px;
  box-sizing: border-box;
  height: 42px;
  line-height: 40px;
  padding-right: 40px;
  padding-left: 20px;
  width: 100%;

  .headerLeft {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  a {
    background-color: white;
  }

  .slideShowOptions {
    float: right;
  }

  .saveStatus {
    display: inline-block;
    margin-right: 5px;
  }
}

.fullWidthMax {
  max-width: 100%;
}

.slideShowBuilderBody {
  position: relative;
  display: flex;
  height: 100%;
  overflow: auto;

  .slidePanel {
    background: $med-grey;
    overflow-y: scroll;
    padding: 10px;
    max-width: 200px;
    min-width: 200px;

    .dragContainer {
      height: 100px;
      margin-bottom: 10px;
      padding-top: 5px;
    }

    .selected {
      background: #{$slideFocusActiveColor}66; // 40% opacity
    }
  }

  .slideEditor {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;

    .templatePicker {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-top: 2em;

      .templateOption {
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin: 10px;
        border: none;

        &:hover {
          background: $med-grey;
        }

        .templateTitle {
          margin: 0 auto 5px auto;
        }
      }
    }

    .templateActions {
      display: flex;
      float: right;
      margin-top: 5px;
      gap: 5px;
    }
  }

  .actionsButtonWrapper {
    position: absolute;
    top: 10px;
    right: 25px;
    z-index: 2;

    .actionsButton {
      padding: 0 8px;
      font-size: 16px;
    }
  }
}

.templatePicker {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.templatePickerModal {
    overflow: auto;
    max-height: 600px;
    min-width: 750px;
    height: auto;
  }

  .templateOption {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 10px;
    border: none;

    &:hover {
      background: $med-grey;
    }

    .templateTitle {
      position: relative;
      width: 100%;
      max-width: 150px;
      margin: 0 auto 5px auto;

      img {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 15px;
        height: auto;
      }
    }
  }
}

.textWithIcon {
  align-items: center;
  display: flex;

  img {
    margin-right: 10px;
    width: 22px;
  }

  i {
    font-size: 20px;
    margin-right: 10px;
  }
}

button:hover, a:hover {
  .textWithIcon img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(192deg) brightness(103%) contrast(103%);
  }
}
