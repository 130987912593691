.settingsForm {
  margin: 1em;

  .checkboxRow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .checkboxLabel {
    margin-left: 10px;
    width: 100%;
  }

  .checkboxField {
    display: flex;
    justify-content: flex-end;
  }

  hr {
    margin: 7px 0;
  }

  .labelStyle {
    color: #3c3c3c !important;
    font-family: Lato, Helvetica, Arial, sans-serif !important;;
    font-size: 14px !important;;
  }
}
