@import '../../colors';

.status {
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin: 0 0.25rem 0 0;
  vertical-align: middle;
  appearance: none;
  outline: 0;
  cursor: pointer;
  background: $white;

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 1px;
    left: 4px;
    width: 3.5px;
    height: 7px;
    border: solid;
    border-color: $green;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    opacity: 0;
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }
}
