@import '~common/colors';

.cardsContainer {
  flex-wrap: wrap;
  margin: -10px;
}

.newCardsContainer {
  height: 100%;
  display: flex;
}

.card {
  margin: 10px;
  padding: 20px;
  width: 437px;

  &.withMinHeight {
    min-height: 175px;
  }
}

.card h2 {
  padding: 0;
}

.generalCard {
  height: 50%;
}

.completedCard {
  flex-basis: 100%;
}

.displayFlex {
  display: flex;
}

.hr {
  margin: 20px 0;
}
