.note {
  white-space: pre-wrap;
  text-align: left;
}

.iconContainer {
  padding-right: .4em;
}

.icon {
  height: 14px;
  width: 14px;
}
