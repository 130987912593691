// TODO: consolidate with our other colors.scss

$red:             #D2232A;
$orange:          #F69C05;
$green:           #97AB2C;
$dark-green:      #2A8806;
$blue:            #2B9AB8;
$teal-blue:       #247F99;
$tci-purple:      #522E91;

$white:           #FFF;
$light-grey:      #EEE;
$med-grey:        #CCC;
$dark-grey:       #777770;
$very-dark-grey:  #555;
$dark:            #333;
$black:           #000;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "red":            $red,
    "orange":         $orange,
    "green":          $green,
    "blue":           $blue,
    "purple":         $tci-purple,
    "white":          $white,
    "light-grey":     $light-grey,
    "med-grey":       $med-grey,
    "dark-grey":      $dark-grey,
    "very-dark-grey": $very-dark-grey,
    "dark":           $dark,
    "black":          $black
  ),
  $theme-colors
);
