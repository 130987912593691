.prompt {
  margin-left: 5px;
  line-height: 200%;
}

.textSegment {
  display: inline;
}

.dropdownSegment {
  display: inline-block;
}
