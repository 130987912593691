@import '~common/colors';

.fieldRow {
  display: flex;
  align-items: center;
  padding: 10px 0;

  i {
    margin-left: 3px;
  }
}

.label {
  width: 130px;
  font-weight: bold;
}

.fieldColumn {
  flex-grow: 1;

  textarea {
    border: 1px solid $med-grey;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    height: 70px;
    box-sizing: border-box;
  }

  input[type="text"] {
    border: 1px solid $med-grey;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
  }
}

.error {
  margin-top: 5px;
  color: red;
}

.requiredAsterisk {
  color: red;
}

.numberField {
  border-radius: 4px;
  border: 1px solid $med-grey;
  color: #333;
  font-size: 14px;
  width: 50px;
  padding: 10px;
}

.fileTypeText {
  display: block;
  font-size: 11px;
  line-height: 20px;
}
