@import '~common/colors';

.formContainer {
  margin: 20px;
  width: 100%;
}

.infoGroup {
  width: 50%;
  margin-bottom: 30px;
}

.fieldGroup {
  width: 50%;
  margin-bottom: 30px;
}

.input {
  display: block;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: 12px;
}

.label {
  margin-bottom: 5px;
}

.divider {
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid #eee;
}

.fieldError {
  color: $red;
}
