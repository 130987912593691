.modalBody {
  overflow: visible;
  line-height: 120%;

  ul {
    list-style-type: disc;
  }

  li {
    margin-left: 1.5rem;
  }
}
