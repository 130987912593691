.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.questionCircle {
  margin-bottom: 2px;
  margin-left: 1px;
}

.label {
  width: 65px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.textInput {
  width: 87%;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: 12px;
}

.input {
  width: 89%;
}

.checkboxLabel {
  padding-bottom: 2px;
}

.required {
  color: red;
}
