@import './variables';
@import './colors';

@mixin transform_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin image_button {
  border: none;
  background: transparent;
  cursor: pointer;
}

@mixin hover_brightness {
  &:hover {
    filter: brightness(150%);
  }
}

@mixin close_icon {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  cursor: pointer;
}

@mixin border($color) {
  border: 1px solid $color;
}

@mixin hide_scrollbar {
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
}

@mixin truncate($max-line) {
  display: -webkit-box;
  -webkit-line-clamp: $max-line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
