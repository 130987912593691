.button {
  display: block !important;
}

.container {
  position: relative;
}

.dropdown {
  display: block;
  position: absolute;
  z-index: 1050;
  border: 1px solid #D4D4D4;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px #0000001A;

  &.closed {
    display: none;
  }

  &.top {
    bottom: 100%;
  }

  &.bottom {
    top: 100%;
  }

  &.left, &.right {
    top: 0;
  }

  &.left {
    right: 100%;
  }

  &.right {
    left: 100%;
  }

  &.top-end {
    bottom: 100%;
    right: 0;
  }

  &.bottom-start {
    top: 100%;
    left: 0;
  }
}
