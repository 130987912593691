.callOut {
  position: absolute;
  top: 5px;
  right: -7px;
  background-color: white;
  padding: 6px 10px;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .12);
  border-left-width: 5px;
  border-left-style: solid;
  box-sizing: border-box;
  font-size: 13px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    border: 3px solid #bfbfbf;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}
