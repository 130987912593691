.select {
  font-size: 14px;
  background-color: white;
  border: 1px solid #ccc;
}

.selectContainer {
  overflow: visible;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    width: 220px;
  }
}

.indented {
  text-indent: 20px;
}

.notebookScoreLabel {
  width: 225px;
}

.numberFieldContainer {
  display: flex;
  align-items: center;

  div {
    margin-right: 2px;
  }
}
