@import '~assets/stylesheets/abstracts/variables';

.status {
  display: inline-block;
}

.border {
  border: 1px solid $tci-green;
  border-radius: 4px;
  padding: 5px 10px 0;
}

.inline {
  display: inline-block !important;

  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.sectionTitle {
  font-weight: $font-weight-bold;
}

