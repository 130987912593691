.thumbnailContainer {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;

  img {
    max-width: 50px;
    max-height: 50px;
  }
}

.spanishSectionHeader {
  margin-top: 1em;
  font-size: 18px;
}

.spanishDivingLine {
  border-top: solid #eee;
}

.input {
  max-width: 200px;
}

.modalSize {
  width: 800px;
  max-height: 740px;
}

.bodyModalSize {
  max-height: 650px; // 740px-50px-40px this comes from desired height of modal(740) - footer height(50) - padding(40)
}

.warningIcon {
  display: flex;
  align-items: center;

  i {
    color: #f69c08;
    font-size: 24px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.warningText {
  font-size: 14px;
  color: #555555;
}
