@import '../../abstracts/variables';
@import '../../abstracts/mixins';

.modalFieldsWrapper {
  display: flex;
  flex-direction: column;

  .required::after {
    content: "*";
    color: red;
  }
}

.modalField {
  border: 1px solid $med-grey;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 250px;
}

.labelSelect {
  width: 250px;
  z-index: 3;

  &.sectionTypeSelect, &.slideSelect {
    z-index: 1;
  }
}

.videoSelect, .sectionsSelect {
  width: 500px;
}

.vl {
  position: absolute;
  height: 83%;
  top: 90px;
  left: 300px;
  border-left: #e5e5e5 2px solid;
}

.questionSelects {
  position: relative;
  width: 82%;
  height: 75%;
  min-height: 450px;
  padding-left: 20px;
  left: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
  bottom: 220px;
}

.hintIcon, *[class*='hintIcon__'] {
  @extend %hint-button;
}

.hintText,  *[class*='hintText__'] {
  color: blue;
  display: none;
}

.labelText {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.htmlElement, .slidePopup, *[class*='slidePopup__'] {
  margin: 10px;
  background-color: $white;
  border: 1px $tci-purple solid;
  box-shadow: #777 .05em .05em .3em;
  display: none;
  padding: 1%;
  position: fixed;
  width: 250px;
  z-index: 1050;
  height: fit-content;
  text-align: initial;
  font-weight: initial;
  font-style: initial;
}

.htmlElement {
  bottom: 0;
  right: 0;
}

.slidePopup, *[class*='slidePopup__'] {
  &:not([class="htmlElement"]), &:not(.inlineStyles), &:not([class*='inlineStyles__']) {
    bottom: 0;
    right: 0;
  }

  &.video, &.large, &[class*='video__'], &[class*='large__'] {
    width: 92%;
    height: 92%;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.slideTooltipWrapper {
  max-height: 400px;
  overflow: auto;
}

.slidePopupCloseBtn, *[class*='slidePopupCloseBtn__'] {
  background-color: $white;
  border-radius: 50%;
  box-shadow: #777 .05em .05em .3em;
  color: $very-dark-grey;
  cursor: pointer;
  font-size: 17px !important;
  height: 17px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  width: 17px;
}

.full-player-span {
  position: relative;
  display: inline-block;
  max-width: 100%;

  audio {
    max-width: 100%;
  }
}

.forceScrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
