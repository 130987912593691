.container {
  position: relative;
}

.nodes {
  display: flex;
  justify-content: space-between;
}

.addPointButton {
  color: #2A8806;
  text-align: center;
  background-color: white;
  font-size: 1.1rem;
  margin-top: -5px;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  cursor: pointer !important;
}

.deletePointButton {
  display: block;
  color: #d2232a;
  margin: 5px auto;
  font-size: 1.7rem;
  cursor: pointer !important;
}

.saveSuccessful {
  opacity: 0;
  color: #2A8806;
  text-align: center;
  margin-bottom: 10px;
}

.saveSuccessfulIcon {
  color: #26a65b;
  opacity: 0;
}

.dot {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 5px auto;
}

.line {
  display: inline-block;
  background-color: #ccc;
  height: 2px;
  position: absolute;
  z-index: -1;
  top: 9px;
  right: 0;
  bottom: 0;
  left: 70px;
}

.lineWithAddButton {
  width: calc(100% - 70px);
}

.lineWithoutAddButton {
  width: calc(100% - 135px);
}
