@import '~common/colors';
// base modal styles
$common-modal-padding: 1.25rem;

.modalPortal {
  hr {
    margin: $common-modal-padding (-$common-modal-padding);

    &.nearlyFullScreen { margin-top: 0; }
  }

  footer {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: #fff;
  }
}

.overlayBackground {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .75);
  display: flex;
  align-items: center;
}

.modalContainer {
  width: 700px;
  max-height: 80%;
  padding: 0;

  position: relative;

  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #999;
  overflow: hidden;

  &.allowOverflow {
    overflow: visible;
  }

  &.confirm {
    width: 500px;

    footer {
      margin-top: 0;
      border-top: 0;
    }
  }

  .block_link.cancel {
    color: #666;
    background-color: #ddd;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px 0 #fff;
  }

  &.fullScreen {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  &.nearlyFullScreen {
    height: 100%;
    max-height: 95%;
    max-width: 95%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.header {
  padding: $common-modal-padding;
  height: auto;
  position: relative;

  .closeBtn {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);
  }
}

.body {
  padding: $common-modal-padding;
  max-height: 58vh;
  height: auto;
  flex: 1;
  overflow-y: auto;

  &.allowOverflow {
    overflow-y: visible;
  }

  &.fullScreen, &.nearlyFullScreen {
    max-height: 100%;
  }
}

.error {
  text-align: center;
  padding: 100px 0;
}

div.loading {
  background-color: rgba(255, 255, 255, .8);
  position: absolute;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 2em;
  z-index: 10;

  i {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.nearlyFullScreen {
  div.loading {
    height: unset;
    width: 95%;
  }
}

.deleteHeader {
  background-color: $red;

  h1, button {
    color: $white;
  }
}

