.table td { text-align: center; }

.addButton {
  margin-bottom: 7px;
}

.error {
  color: red;
  margin-top: 10px;
}

.actions {
  width: 190px;

  button { margin-left: 3px; }
}
