@import "../../variables";

.collapsible {
  transition: max-height 0.3s ease-out;
  max-height: 3000px;
  overflow: hidden;
  padding: 3px 0 0 3px;
}

.collapsed {
  max-height: 0;
  display: none;
}

.collapseContainer {
  position: relative;
  padding-right: 10px;
  cursor: pointer;
  &:focus {
    color: $tci-purple;
  }
  &:hover {
    color: $tci-purple;
  }
}

.collapseIcon {
  position: absolute;
  right: 0;
  top: 25%;
  transform: translateY(-50%);
}
