@import '~assets/stylesheets/abstracts/variables';

button.dropdownItem {
  cursor: pointer;
  line-height: 1.5;
  font-weight: $font-weight-normal;
  padding: 0.5rem 1.5rem;
  font-size: 100%;
  width: 100%;
}

.submenu {
  position: relative;
  width: 100%;
  border: none;
  background: inherit;
  text-align: inherit;
  color: #555;

  &:hover {
    color: #484848;
    text-decoration: none;
    background-color: #eee;
  }

  .angleRight {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 1.8em;
    font-weight: bold;
  }
}
