.current-slide .fr-wrapper {
  border-color: transparent !important;
}

.fr-active.fr-hidden {
  opacity: 1;
}

.fr-active.fr-popup {
  z-index: 2000 !important;
}

.slide:not(.present) {

  .model-essential-question,
  .model-number,
  .model-title,
  .phenomenon-title,
  .phenomenon,
  .storyline-title,
  .storyline {
    user-select: all;
  }
}
