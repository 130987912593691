.container {
  display: flex;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 0 5px gray;
  flex-direction: column;
  margin: 1em 2em 0 2em;
  min-height: 11em;
  overflow: hidden;
  max-height: 35em;

  .cardTitle {
    display: flex;
    align-items: center;
    flex: 0 0 30%;
    min-height: 50px;
    align-self: flex-start;
    position: sticky;
    width: 100%;
    top: 0;
  }

  .cardBody {
    display: flex;
    flex: 1 1 70%;
    width: 100%;
    justify-content: center;
    overflow-y: auto;
  }
}
