@import '~common/colors';

.red {
  color: $red;
}

.green {
  color: green;
}

.label {
  font-weight: bold;
  width: 130px;
}
