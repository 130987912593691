@import '../../variables';
@import '../../colors';
@import '../../mixins';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 50vh;
  text-align: center;
  font-size: 50px;
}

.modalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 70vh;
  padding: $padding_medium;
}
