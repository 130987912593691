.warningIcon {
  height: 1em;
  color: #f69c08;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.buttonWrapper {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 18px;

  button {
    position: absolute;
    left: 0;
    top: 5px;
    z-index: 10;
  }
}
