@import '~assets/stylesheets/abstracts/variables';

.headerClass {
  padding: 0;

  .tab {
    display: inline-flex;
    align-items: center;
    border: none;
    padding: 1.25rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: $tci-purple;
    cursor: pointer;

    &.active {
      background-color: #fff;
      color: $very-dark-grey;
    }

    &:only-child {
      background-color: inherit !important;
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.sectionIframeContainer {
  height: 100%;
  width: 100%;
  margin: 0 auto;

  .sectionIframe {
    height: 100%;
    width: 100%;
  }
}
