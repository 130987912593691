label.classroomCard {
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;

  img {
    width: 35%;
    margin: 5px auto;
    display: block;
  }
}

label.active {
  border: 1px solid #056bf9;
}

label.disabled {
  background-color: #eee;
  cursor: not-allowed;
  box-shadow: none;
  outline: none;

  &:hover {
    box-shadow: none;
  }
}
