@import '~common/colors';

.videoCard {
  width: 530px;
  display: flex;

  video {
    width: 350px;
    height: intrinsic; /* fixes an issue in Safari with the container height */
    flex-shrink: 0;
  }
}

.videoInfo {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
