@import "./colors";

// global layouts
$margin_smaller: 0.25rem;
$margin_small: 0.5rem;
$margin_medium: 1rem;
$margin_large: 2rem;
$margin_xlarge: 10rem;

$padding_smaller: 0.25rem;
$padding_small: 0.5rem;
$padding_ssmedium: 0.75rem;
$padding_smedium: 0.875rem;
$padding_medium: 1rem;
$padding_large: 1.5rem;
$padding_larger: 2rem;
$padding_xlarge: 4rem;

$radius_small: 2px;
$radius_medium: 0.25rem;
$radius_large: 6px;

$box_shadow_medium: 0 2px 3px rgba(0, 0, 0, 0.1);

$border_medium: 1px solid $light-grey;
$border_large: 1px solid $med-grey;
$border_xlarge: 2px solid $dark-grey;

$almost_full: 95%;

// video card styles
$video_card_width: 15rem;
$video_card_image_height: 7rem;
$video_card_image_radius: 0.25rem 0.25rem 0 0;
$video_card_play_width: 3.5rem;

// video player styles
$video_player_titlebar_height: 3rem;
$video_player_content_height: 70vh;
$video_player_bottombar_height: 5rem;

// filter bar styles
$filter_bar_width: 12.5rem;

// modal styles
$modal_z_index: 1040;
$modal_overlay_background: rgba(0, 0, 0, 0.75) !important;

// video card styles
$game_card_width: 23rem;
$game_card_image_height: 7rem;
$game_card_image_radius: 0.25rem 0.25rem 0 0;

// benchmark styles
$benchmark_main_column_width: 12rem;
$benchmark_cell_width: 3.75rem;
$benchmark_cell_height: 3.75rem;
$performance_row_height: 2.5rem;
