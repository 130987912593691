.languageOptionsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 20px;
}

.modal {
  width: 90%;
  max-width: 800px;
  border-radius: 8px;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.content {
  padding: 5px;
}

.warningBox {
  background-color: #fff8e1;
  border: 2px solid #ffdfba;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;

  i {
    color: #f69c08;
    font-size: 24px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.warningText {
  font-size: 14px;
  color: #555555;
}

.languageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 25px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e9ecef;
  }
}

.printIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

