@import '~common/colors';

.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  width: 84px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.textInput, .textArea {
  width: 87%;
  border: none;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;

  &.comp {
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    height: 12px;
  }
}

.textArea {
  height: 100px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input {
  width: 89%;
}

.dateInput {
  width: 168px;
}

.required {
  color: red;
}

.select {
  width: 90%;
}

.hasError {
  border-color: red;
}

.seatCountInput {
  width: 80px;

  &:disabled { background-color: #efefef; }
}

.picker {
  width: 180px;
  border-radius: 4px;
  border: 1px solid $med-grey;
  line-height: 32px;
  font-size: 1em;
  margin-right: 5px;
  padding: 0 10px;
}

.datepicker {
  margin-left: -4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  height: 12px;
}
