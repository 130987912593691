@import '~common/colors';

.header {
  height: 110px;
  overflow: hidden;
  position: relative;
  background-color: $light-grey;

  display: flex;
  align-items: center;
  justify-content: center;
}

.moduleIcon {
  width: 80px;
}

.img {
  width: 290px;
  height: auto;
}

.body {
  height: 120px;
  padding: 20px;

  &.studentView {
    height: auto;
  }
}

.title {
  composes: title from '../../LessonOverview/Cards/Cards.module.scss';
  margin-bottom: 1em;

  &.studentView {
    margin-bottom: 0;
  }
}

.titleLink { composes: titleLink from '../../LessonOverview/Cards/Cards.module.scss'; }

.description {
  color: $very-dark-grey;
  font-size: 1em;
  line-height: 20px;
}
