@import '~common/colors';

.syncOverview {
  padding-bottom: 15px;

  span {
    padding-right: 10px;
  }
}

.errorTitle {
  font-size: 15px;
  padding: 10px 0;
  color: $very-dark-grey;
}

.errorContainer {
  width: 90%;
  overflow: auto;
  font-size: 12px;
  border: 1px solid $med-grey;
}

.errorText {
  padding-top: 10px;
  font-size: 14px;
}
