.dropZone {
  background-color: #eee;
  border: 1px dashed #555;
  border-radius: 5px;
  font-size: 1rem;
  position: absolute;

  &.answered {
    border-style: solid;
    border-width: 2px;

    &.correct {
      border-color: #2A8806;
    }

    &.incorrect {
      border-color: #D2232A;
    }
  }


  &.highlighted {
    background-color: lightyellow;

    &.transparentBackgroundDragging {
      background-color: rgba(238, 238, 238, .7);
    }
  }

  &.transparentBackgroundDragging {
    background-color: rgba(238, 238, 238, .4);
  }

  &.transparentBackgroundNotDragging {
    background: transparent;
    border: none;
  }

  &.moreAnswers:after {
    content: '...';
    position: absolute;
    bottom: 10px;
  }

  &.rectangle {
    border-radius: 5px;
  }

  &.circle {
    border-radius: 50%;
  }

  .dropZoneContent {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    &Children {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }
}



.label {
  position: absolute;
  user-select: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px 0 0 0;
  width: 100%;
  padding: 2px;
  color: #222;
  text-align: center;
}
