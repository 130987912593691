@import '~common/colors';

.sectionsContainer {
  padding: 3rem 2rem;
  overflow: auto;
  flex-grow: 1;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  padding: 0 1.25rem 1.25rem;
}

.footer hr {
  margin-top: 0;
}

.draftMessage {
  position: absolute;
  left: 40px;
  height: 30px;
  display: flex;
  align-items: center;

  .lmsLogo {
    margin-right: 10px;
    max-height: 75%;
  }

  .lmsLink {
    padding-left: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0;
    max-width: 1200px;
    margin: auto;
  }
}
