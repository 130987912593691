.cardInfo {
  align-items: stretch;
  background: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  width: 92%;
  min-height: 9em;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  .cardImage {
    display: flex;
    flex: 0 0 10%;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      height: auto;
    }
  }

  .cardDetails {
    display: flex;
    flex: 1 1 60%;
    flex-direction: column;
    justify-content: center;
    padding: 1em 0 1em 1em;

    .cardTitle {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .assignmentDirections {
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    line-height: 16px;
    padding: 1em;
  }
}

.cardBorder {
  border: 1px solid #ccc;
}
