@import '~common/colors';

.contentContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: auto;
  top: 44px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tooltipShadow {
  box-shadow: 0 3px 11px 0 #00000066;
}

.tabsContainer {
  padding-top: 25px;
  flex-grow: 1;
}

.info {
  padding: 20px;
}

.subscriberName {
  color: white;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 120%;
  font-size: 12px;
}

.green { color: $green; }
.orange { color: $orange; }

.expandCollapseButton {
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  background-size: contain;
  border: 8px solid white;
  position: fixed;
  top: 57px;
  background-color: white;

  &.expand {
    background-image: url('./sidebar-expand.svg');
    left: 17px;
  }

  &.collapse {
    background-image: url('./sidebar-collapse.svg');
    left: 205px;
  }
}

.studentBorder .expandCollapseButton {
  top: 107px;

  &.expand {
    left: 27px;
  }

  &.collapse {
    left: 215px;
  }
}

.contentFlex {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 250px;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.languageDropdown {
  width: inherit;
  margin-left: 0;
}

.collapsed {
  width: 65px;
}
