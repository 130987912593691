@import '../../abstracts/variables';

.questionPickerContainer {
  display: flex;
  flex-direction: column;
}

.htmlContainer {
  height: 100%;
  width: 100%;
}

.slideQuestionsSelects {
  display: flex;
  width: 95%;

  .select {
    margin-right: 10px;

    label {
      p {
        margin-top: unset !important;
      }
    }
  }
}

.questionGroupCheckboxContainer {
  display: flex;
  min-height: 35px;
  margin: 0 20px;
  border-bottom: 1px solid $med-grey;
  padding: 5px 0;

  label {
    width: 100%;
    display: flex;
    align-items: center;

    p { margin-bottom: unset !important; }
  }
}
