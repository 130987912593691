@import '../../variables';
@import '../../mixins';

.bar {
  @include hide_scrollbar;
  position: sticky;
  top: 3.5rem;
  height: 0%;
  flex: 0 0 $filter_bar_width;
  text-align: left;
  padding: $padding_medium;
  box-sizing: border-box;
}

.barTitle {
  font-size: large;
  font-weight: bold;
  margin-bottom: $margin_small;
}

.clearButton {
  position: absolute;
  right: 0;
  top: 0.2rem;
}

.categoryTitle {
  color: $dark;
  font-size: medium;
  font-weight: bold;
  margin: $margin_small 0;
}

.innerFilter {
  font-size: smaller;
  padding-left: $padding_medium;
}
