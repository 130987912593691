@import '~common/colors';

.header {
  composes: header from '../../LessonOverview/Cards/LargeCard.module.scss';

  .lowerIcon {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}

.img {
  width: 75px;
  height: auto;
}

.body {
  height: 120px;
  padding: 20px;
  overflow-y: auto;
}

.title {
  color: $tci-purple;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 1em;
}

.titleLink:hover {
  color: $very-dark-grey;
}

.description {
  composes: description from '../../LessonOverview/Cards/LargeCard.module.scss';

  display: flex;
  flex-flow: column;

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
  }
}

.banner {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, 0);
  height: auto;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
  border-top: 2px solid;
  border-radius: 3px;
  color: #555555;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 3px;
  z-index: 1;

  &.green {
    border-color: green;
  }

  &.blue {
    border-color: #247F99;
  }

  &.orange {
    border-color: $orange;
  }

  &.very-dark-grey {
    border-color: $very-dark-grey;
  }
}

.bar {
  composes: bar from '../../LessonOverview/Cards/ActionBar/ActionBar.module.scss';
}

.actions {
  composes: actions from '../../LessonOverview/Cards/ActionBar/ActionBar.module.scss';
}

.card {
  position: relative;
  flex-shrink: 0;
  composes: card from '../../LessonOverview/Cards/Cards.module.scss';
}

.iconCircle {
  composes: iconCircle from '../../LessonOverview/Cards/ActionBar/ButtonWithTooltip.module.scss';
}

.iconLink {
  composes: iconLink from '../../LessonOverview/Cards/ActionBar/ButtonWithTooltip.module.scss';
}

.icon {
  composes: icon from '../../LessonOverview/Cards/ActionBar/ButtonWithTooltip.module.scss';
}

.assigned {
  color: green !important;
}

.assignmentTooltip {
  .tooltipHeader {
    font-weight: bold;
    margin-top: 10px;
  }

  hr {
    margin: 5px 0 !important;
    color: #eee;
  }
}

.lessonOverviewWrapper {
  counter-reset: list-number;
  margin-top: 15px;
  width: 1050px;

  .lessonSubheader {
    border-left: #777770 2px solid;

    .subheaderNumber {
      position: absolute;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      background: #777770;
      left: -19.5px;
    }

    .subheaderNumber:before {
      counter-increment: list-number;
      content: counter(list-number);
    }

    .subheaderHeader {
      display: flex;
      flex-direction: column;
      padding-left: 35px;

      .subheaderTitle {
        color: #3c3c3c;
        font-size: 18px;
        font-weight: bold;
      }

      .subheaderDescription {
        color: #555555;
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .subheaderCards {
      display: flex;
      margin-top: 15px;
      flex-wrap: wrap;
      padding-left: 30px;
    }
  }

  .lessonSubheader:last-of-type {
    border-left: 2px white;
  }
}
