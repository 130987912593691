@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.textareaContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .textarea {
    width: 100%;
    height: 100%;
    padding-top: 3px;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.editIcon {
  position: absolute;
  font-size: 10px;
  color: #fff;
  top: -7px;
  left: -7px;
  background-color: $tci-purple;
  height: 14px;
  width: 14px;
  border-radius: 7px;
  line-height: 12px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .4);
  cursor: pointer !important;
  z-index: 1;
}

.textAreaToolbar {
  height: 100px;
}
