@import '~common/colors';

.modal {
  overflow: visible;
}

.container {
  padding: 20px;
}

.radio-title {
  font-weight: bolder;
}

.radio-label {
  display: block;
}

.spacer {
  line-height: 31px;
}

.hidden {
  visibility: hidden;
}

.edit-lesson-number {
  border: none;
  float: right;
  padding: 1px !important;
}

.lesson-num-dropdown {
  position: absolute;
  z-index: 100;
  left: 40px;
  bottom: 0;
  top: 63%;
  display: none;
  float: left;
  min-width: 120px;
  padding: 10px 0;
  margin: 10px 0 0;
  font-size: 15px;
  color: black;
  text-align: left;
  list-style: none;
  background-color: white;
  border: 1px solid black;
  border-radius: 0;

  i {
    margin-right: 5px;
  }
}

.icon {
  font-size: 25px;
  width: 30px;
  margin: auto;
  text-align: center;
  white-space: pre-wrap;
}

.custom-dropdown-item {
  display: block;
  background-color: white;
  padding: 2px 15px 10px;
  color: $very-dark-grey;

  &:hover,
  &:focus {
    cursor: pointer;
    color: darken($very-dark-grey, 5%);
    text-decoration: none;
    background-color: $light-grey;
  }
}

