@import '~common/colors';

.cardWrapper {
  border: $med-grey 1px solid;
  border-radius: 5px;
  width: 40%;
  padding: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 2px $light-grey;

  &.fullWidth {
    width: 100%;
    margin-right: 0;
  }

  .cardHeader {
    font-size: 18px;
    margin-bottom: 15px;

    &.headerWithIcon {
      display: flex;
      align-items: center;

      .integrationIcon {
        height: 18px;
        margin-left: 5px;
      }
    }
  }

  .cardBody {
    display: flex;

    .cardContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .icon {
      height: 63px;
      margin-right: 10px;
    }
  }
}
