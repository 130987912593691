.notifications {
  position: relative;
}

button.giftboxButton {
  padding: 0;

  img {
    height: 24px;
  }

  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
    transition: none;
  }
}

.retiringProgramHeader {
  background-color: #d2232a;

  .retiringProgramMessage {
    justify-content: space-between !important;
    color: white;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
    margin-left: 5px;
  }

  .closeHeaderButton {
    border: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
    color: white;
    cursor: pointer;
    font-size: 30px;
    font-weight: lighter;
  }
}

.hide {
  display: none;
}
