@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.dashboardContainer {
  .header {
    display: flex;
    justify-content: space-between;
    h1 { margin: 22px 0; }
  }
}

.quickLinksContainer {
  display: flex;
  justify-content: space-between;

  button {
    font-family: $font-family-sans-serif;
  }
}

.quickLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 180px;
  border-radius: 5px;
  margin: 0 20px;
  background-color: #fff;

  .icon {
    height: auto;
    margin-top: auto;

    &.large {
      width: 35%;
    }

    &.small {
      width: 25%;
    }
  }

  p {
    color: $tci-purple;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: 45px;
  }

  &.green {
    border-left: 5px solid $tci-green;
  }

  &.purple {
    border-left: 5px solid $tci-purple;
  }

  &.orange {
    border-left: 5px solid $orange;
  }

  &.red {
    border-left: 5px solid #68DBF9;
  }

  &:hover {
    cursor: pointer;
  }
}

.dashboardCard {
  box-shadow: none !important;
  background-color: #FFFF !important;

  &.blue {
    border-left: 5px solid $blue;
  }

  &.red {
    border-left: 5px solid $red;
  }

  .cardHeader {
    margin-bottom: 0;
    margin-top: 15px;
    overflow: hidden;
    padding-bottom: 5px;

    h1, h2 {
      margin: 0;
      padding: 0;
      color: $very-dark-grey;
    }

    .arrow {
      font-size: 1.5em;
    }
  }

  .cardBody {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100px;

    .cardBodyContent {
      font-size: 18px;
      margin: auto;
      background-color: #FFFF;
    }
  }

  .badgesContainer {
    display: flex;
    flex-wrap: wrap;

    .badge {
      margin: 15px;

      img {
        max-width: 140px;
      }
    }
  }

  .collapsed {
    min-height: 100px;
    max-height: 175px;
    overflow: scroll;
  }

  .cardFooter {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.assignmentsCard {
  table {
    border: none;

    td {
      border: none;
    }
  }
}

.signInAsStudentContainer {
  width: 30%;
  margin: auto 0 auto auto;

  .selectLabel {
    margin-left: 5px;
    margin-bottom: -3px;
    backgroundColor: '#fff';

    .key {
      height: 1em;
      margin-left: 5px;
    }
  }

  .select {
    margin: 0 0 10px 0;
  }
}

.shortcutsHeader {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.retiredProgramModal {
  border: 0;

  &:focus {
    outline: none;
  }

  .retiredProgramModalHeader {
    background-color: $red;
    padding: 20px;
    color: white;
    font-weight: 400;

    i {
      padding-right: 8px;
    }
  }

  .retiredProgramBody {
    font-size: 1.2em;
    line-height: 1.1em;
    padding: 0 1.25rem 30px;

    .retiredProgramInlineText {
      display: inline-block;
    }

    a {
      text-decoration: underline;
    }
  }

  footer {
    border-top: 1px solid $med-grey;
    padding: 15px 1.25rem 0;
  }
}

.retiredProgramModalHeaderOverride {
  padding: 0;

  button {
    color: white;
  }
}

.retiredProgramModalBodyOverride {
  padding-left: 0;
  padding-right: 0;
}
