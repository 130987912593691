.warningContainer {
  p, li { line-height: 1.5em; }

  ul li {
    list-style-type: disc;
    list-style-position: inside;
    margin: .25em 0;
    text-indent: -1.4em;
    padding-left: 1.4em;
  }
}
