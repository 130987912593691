.margin {
  margin-top: 8px;
  margin-left: 5px;
}

.dropdown {
  margin-top: 8px;
  margin-left: 5px;
  min-width: 215px;
}

.dropdownItem {
  width: 100%;
  box-shadow: none !important;
}

.myClass {
  table {
    table-layout: fixed !important;
  }
}
