@import '~common/colors';

.inlineRow {
  display: flex;
  align-items: center;
}

.onIcon {
  color: $green;
  margin-right: 5px;
  margin-left: 7px;
}

.offIcon {
  color: $orange;
  margin-right: 5px;
  margin-left: 7px;
}

.radioContainer {
  display: flex;
  justify-content: flex-start;
  width: 250px;
  align-items: center;
  margin-left: 100px;

  input { margin-right: 5px; }
}

.investigationGroup {
  width: 355px;
}

.radio {
  flex-basis: 40%;
}

.centeredText {
  display: flex;
  text-align: center;
  align-items: center;
  white-space: nowrap;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: white;
  padding: 1em 0;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 20px;
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}
