.label {
  width: 35%;
}

.footer {
  margin-top: 60px;
}

.message {
  text-align: center;
}

.heading {
  font-weight: bold;
  margin-top: 10px;
}

.addProgramLabel {
  flex-basis: 100%;
  margin-bottom: 10px;
}

.fieldRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  i {
    margin-left: 3px;
  }
}

.fieldColumn {
  flex-grow: 1;
}

.row {
  width: 40%;
}
