.signInModal {
  width: 600px;

  header {
    background-color: white;
    padding-bottom: .75rem;
  }
}

.blur { filter: blur(10px); }
