.drawer {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 100vw;
  width: 50%;
  background: #fff;
  outline: none;
  border-left: 1px solid #eee;
  box-shadow: -8px 0 20px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  height: 100%;

  // remove any margin from `p` tags with immediate `p` siblings
  p:has(+ p) {
    margin: 0;
  }

  video,
  image {
    max-width: 100%;
  }

  &.fullWidth {
    width: 100%;

    video,
    image {
      width: 50% !important;
    }
  }

  &[data-entering] {
    animation: drawer-slide 300ms;
  }

  &[data-exiting] {
    animation: drawer-slide 300ms reverse ease-in;
  }
}

@keyframes drawer-blur {
  from {
    background: rgba(45, 0, 0, 0);
    backdrop-filter: blur(0);
  }

  to {
    background: rgba(45, 0, 0, .3);
    backdrop-filter: blur(10px);
  }
}

@keyframes drawer-slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
