@import '../../../../../assets/stylesheets/abstracts/functions';
@import '../../../../../assets/stylesheets/abstracts/variables';
@import '../../../../../assets/stylesheets/abstracts/mixins';
@import '../../../../stylesheets/components/buttons';

.new-section-text {
  .section-editor {
    .fr-toolbar {
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    .fr-wrapper {
      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
      border-radius: 0;
    }
  }

  .two-column {
    display: flex;

    .fr-inner {
      width: 50%;
    }
  }

  .fr-view .captions {
    cursor: pointer;
    max-width: max-content;
    display: flex;
    flex-direction: column;

    img {
      max-width: 100% !important;
      box-sizing: border-box;
      flex-grow: 1;
      object-fit: inherit;
      margin: 0;
    }

    .caption {
      flex-shrink: 1;
      flex-basis: auto;
      box-sizing: border-box;
    }

    &.break-text {
      margin: 5px auto;
      width: 100%;
      float: none;

      &.align-left {
        margin-left: 0;
      }

      &.align-right {
        margin-right: 0;
      }
    }
  }

  .caption {
    background-color: #eee;
    padding: 10px;
    line-height: 21px;
    font-size: 16px;
    margin-bottom: 0;
  }

  .align-left,
  .align-right {
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }

  .align-left {
    float: left;
  }

  .align-right {
    float: right;
  }

  .break-text {
    margin: 5px auto;
    display: block;
    float: none;
  }

  .border-rounded {
    border: 1px solid transparent;
  }

  .border {
    box-sizing: content-box;
    border: solid 5px #ccc;
  }

  .border-rounded {
    border-radius: 10px;
    background-clip: padding-box;
    overflow: hidden;
  }

  .border-shadowed {
    box-shadow: 10px 10px 5px 0px #ccc;
  }

  &.main-ideas-editing-mode {
    .main-idea {
      color: rgb(52, 52, 200);
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.main-ideas-svg {
  &.active {
    path {
      fill: #21A9D0 !important;
    }
  }
}

.vocab-box {
  padding: 8px;
  border: 3px solid #b3bc35;

  .vocab-box-header {
    display: flex;
    justify-content: space-between;
  }

  .vocab-title {
    color: #d2232a;
    font-size: 20px;
    font-weight: 700;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1.6;
  }

  .vocab-terms {
    display: flex;
    flex-wrap: wrap;
    gap: 1em 2em;
    padding-top: .5em;

    .vocab-term {
      display: inline-block;
      color: $blue;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 0;
      line-height: inherit;
      font-size: 14px;
    }
  }
}

.fr-view a.vocab-btn {
  text-decoration: none;

  @extend .btn;
  @extend .btn--outline-purple;
  @extend .btn--sm;
}
