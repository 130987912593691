@import '~assets/stylesheets/abstracts/variables';

.editMethodsContainer {
  margin-left: 25px;
  border: 1px solid #ccc;
  border-radius: .25rem;
  padding: 15px;
  width: 440px;
}

.radioButton {
  margin-right: 10px;
}

.disabled {
  color: $med-grey;
  cursor: not-allowed;
}
