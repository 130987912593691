@import '~assets/stylesheets/abstracts/variables';

.statusIcon {
  font-size: 25px;
}

.error { color: $red }
.success { color: $green }

.contactAdmin { font-size: 12px; }
