.googleClassroomButton {
  display: flex;
  align-items: center;

  img {
    width: 18px;
    margin-right: 6px;
  }
}

.modal {
  width: 800px;
}

.modalBody {
  margin-bottom: 70px;
}
