@import "../../colors";
@import "../../variables";
@import "../../mixins";

.videoCard {
  width: $video_card_width;
  margin-bottom: $margin_medium;
  margin-left: $margin_medium;
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1.2;
}

.cardImg {
  cursor: pointer;
  position: relative;
  height: $video_card_image_height;

  > img {
    width: $video_card_width;
    height: $video_card_image_height;
    border-radius: $video_card_image_radius;
  }
}

.blackImg {
  background-color: $black;
  border-radius: $video_card_image_radius;

  > img {
    display: none;
  }
}

.playButton {
  img {
    width: $video_card_play_width;
    @include hover_brightness;
  }
  @include image_button;
  @include transform_center;
}

.videoLength {
  padding: $padding_smaller $padding_small;
  color: $white;
  font-size: 14px;
  background: rgba(60, 60, 60, 0.84);
  border-top-left-radius: $radius_medium;
}

.cardTitle {
  color: $tci-purple;
  font-weight: bold;
  font-size: medium;
  overflow-wrap: break-word;
  margin-bottom: $margin_small;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cardContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: $padding_small $padding_medium $padding_medium $padding_medium;
}

.cardSubtitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: smaller;
  color: $very-dark-grey;
  margin-bottom: $margin_smaller;
}

.included_in {
  font-size: small;
  color: $very-dark-grey;
  margin-bottom: $margin_small;

  img {
    vertical-align: middle;
  }
}

.publishButton {
  padding: $padding_smaller $padding_larger $padding_smaller $padding_medium !important;
  margin-top: auto;
  align-self: flex-start;
}
