@import '~assets/stylesheets/pdfkit/slide_builder_modules/slide_object_questions.scss';
@import '~common/ModelSelect/index.module.scss';


.iconContainer {
  display: flex;
  justify-content: center;
  width: 30px;

  .sliderQuestionIcon { width: 25px; }
}

.dragAndDropContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.thumbnailContainer {
  height: 100%;
}
