.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  width: 130px;
  margin-right: 10px;
  overflow: visible;
  font-weight: bold;
}

.textInput {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: 12px;
}

.form {
  padding-right: 50px;
}

.infoText {
  text-align: right;
}

.p2 {
  padding-left: 2px;
}
