.divider {
  color: #eee;
}

.imgContainer {
  display: flex;
  justify-content: center;
  height: 200px;
}
.slideshowsTab {
  padding-top: 20px;
  max-height: 75px;
  width: auto;
  height: auto;
}
