@import 'app/frontend/javascripts/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/ElementInsert/ElementInsert.module';

.popup {
  @extend .slidePopup;
  @extend .large;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  display: unset;

  &.closed {
    display: none;
  }
}

.closeButton {
  @extend .slidePopupCloseBtn;
}
