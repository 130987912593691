@import '~assets/stylesheets/abstracts/variables';

.settings_container {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: white;
  border-radius: $border-radius;
  margin-top: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

.contentContainer {
  padding-left: 40px;
  flex-basis: 60%;
}
