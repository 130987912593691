@import '~common/colors';

.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 2em;
  font-weight: bold;
}

.cards {
  display: flex;
  margin-top: 5px;
  flex-flow: row wrap;
  width: 1050px;
}

.card {
  width: 18.125rem;
  margin: 1.1875rem;
}

.title {
  color: $tci-purple;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
}

.titleLink:hover { color: $very-dark-grey; }
