@import '~assets/stylesheets/abstracts/variables';
@import '~assets/stylesheets/abstracts/mixins';

.draggableChoice {
  position: relative;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  background-color: #fff;
  list-style: disc !important;

  @include cursor-grab;

  &.transparentBackground {
    background: transparent;
    border: none;
  }

  .content {
    font-size: 14px;
  }

  img {
    height: auto !important;
  }

  img, div {
    @include cursor-grab;

    &:active {
      @include cursor-grabbing;
    }
  }

  ul {
    list-style: unset !important;
  }

  &:active {
    @include cursor-grabbing;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.rectangle {
  border-radius: 5px;
}

.circle {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
}
