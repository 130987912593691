@import '~assets/stylesheets/abstracts/variables';

.addDropZone {
  border: 1px solid black;
  height: 100px;
  width: 200px;
}

.draggableChoices {
  height: 750px;
  position: relative;
  width: 750px;
  background-color: #eee;
}

.dropZonesWrapper {
  width: 640px;
  min-height: 690px;
  width: fit-content;
}

.containerWrapper {
  display: flex;
  overflow: hidden;
}

.bgContainer {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
}

.gridDisplay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 10px 10px;
  background-image:
          linear-gradient(to right, #eee 1px, transparent 1px),
          linear-gradient(to bottom, #eee 1px, transparent 1px);
}

.dropZones {
  // the width x height values here determine the aspect ratio for the builder
  width: 640px;
  height: 540px;

  border: 1px solid #ccc;
  border-left: none;
  position: relative;
  margin: unset !important;
  flex-shrink: 0;
}

.draggableContainer {
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.saveStatus {
  color: $green;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;

  .saveSymbol {
    height: 15px;
    margin-right: 5px;
  }
}

.copyButton, .deleteButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  top: -7px;
  color: #fff;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  line-height: 12px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .4);
  cursor: pointer !important;
}

.copyButton {
  left: -7px;
  background-color: #2A8806;

  > i {
    cursor: pointer !important;
  }

  .fa.fa-copy {
    cursor: pointer !important;
  }
}

.deleteButton {
  right: -7px;
  background-color: #d2242a;
  z-index: 1049;
}

.resizableIcon {
  cursor: se-resize !important;
  display: block;
  z-index: 1048;
  position: absolute;
  padding: 16px;
  bottom: 4px;
  right: -3px;
  transform: rotate(-45deg);

  .line1 {
    width: 21px;
    height: 1px;
    background: gray;
    margin: 0 auto 3px;
    border-radius: 4px;
  }

  .line2 {
    width: 13px;
    height: 1px;
    background: gray;
    margin: 0 auto;
    border-radius: 5px;
  }
}

.customDragLayer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.controlsHeader {
  display: inline-flex;
  gap: 8px;
  margin: 1em 0 1em 1em;
}
