@import '~common/colors';

.radioButtonWrapper {
  display: flex;
  margin-bottom: 5px;
}

.radioButton {
  margin-right: 10px;
}

.warningWrapper {
  display: flex;
  margin-left: 25px;
}

.warningList {
  list-style: initial;
  padding-left: 20px;
}

.exclamation {
  color: $orange;
  margin-right: 5px;
  font-size: 1.25rem;
}

.error {
  color: red;
}
