@import '~assets/stylesheets/abstracts/variables';

.stampPicker {
  padding: 5px;
  max-width: 290px;
}

.header {
  text-align: start;
  font-weight: bold;
}

.virtualStamp {
  margin: auto;
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
}

.stampList {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  .stamp {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.stampButton {
  background-color: white;
  border: 1px solid #ccc;
  border-right: 0;
  padding: 5px 6px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: rgb(117, 117, 117);
  width: 100px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.addStamp {
    height: 30px;
  }
}

.removeStampButton {
  color: $red;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  cursor: pointer;
}

.container {
  position: relative;
}
