@import '~assets/stylesheets/abstracts/variables';

.draggableChoiceContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  row-gap: 2em;
  padding: 1em;

  .numberedChoiceContainer {
    display: flex;
    width: 100%;

    .choiceNumberWithControls {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5px;
      font-weight: bold;
      font-size: 16px;

      .iconWrapper {
        font-size: 12px;
      }

      i {
        cursor: pointer;
      }

      .circleIcon {
        color: $tci-purple !important;
      }

      .chevronLeftIcon {
        margin-left: -1px;
        margin-top: 1px;
      }

      .chevronRightIcon {
        margin-left: 1px;
        margin-top: 1px;
      }
    }
  }
}

.draggableChoicesContainer {
  background-color: #eee;
  width: 640px;
  margin: unset !important;
  border: 1px solid #eee;

  .choicesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    row-gap: 2em;
    padding: 1em 0;
  }

  :global(.fr-view) {
    line-height: normal;
  }

  &.highlighted {
    background-color: lightyellow;
  }
}

.addChoice {
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer !important;
  padding: 0.5rem 1rem;
  background-color: transparent;
  width: 120px;
  text-align: center;
  font-weight: 800;
}

.draggableChoiceCard {
  width: 330px;
  min-height: 300px;
  position: relative;
}

.draggableChoice {
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.addImageButtonContainer {
  padding: 0.75em;
  border-bottom: 1px solid #ccc;
}

.inputField {
  width: 100%;
  box-sizing: border-box;
}

.inputContainer {
  padding: 0.75em;
}

.addDraggableChoice {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10em;
  cursor: pointer !important;
  margin-left: 20px;
}

.vocabOverlay {
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  background-color: #eee;

  .vocabMessageContainer {
    padding: 1em;
    height: 90%;
    display: flex;
    flex-direction: column;

    .vocabMessage {
      font-style: italic;
      flex: 0 0 30%
    }

    .vocabDefinition {
      flex: 1 1 70%;
    }
  }
}
