@import '~common/colors';

.itemWrapper {
  outline: none;
  cursor: pointer;
}

.menuItem {
  margin: 0 20px;
  padding: 15px 5px;
  background-color: white;
  border: none;
  font-family: Lato, Helvetica, Arial, clean, sans-serif;
  color: #3c3c3c;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border-bottom: 2px solid $tci-purple;
  }

  &.active {
    color: $tci-purple;
    font-weight: bold;
    border-bottom: 2px solid $tci-purple;
  }

  &.active:focus {
    outline: 0;
  }

  &:active:focus {
    outline: 0;
  }
}

.itemWrapper:hover .menuItem {
  border-bottom: 2px solid $tci-purple;
}

.prevArrow, .nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 0 15px 5px 15px;
  font-family: 'FontAwesome', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    background-color: $light-grey;
  }
}

.prevArrow::before { content: "\f060"; }

.nextArrow::before { content: "\f061"; }

.hide .prevArrow, .hide .nextArrow {
  cursor: default;

  &::before { content: none; }

  &:hover { background-color: white; }
}
