.menuContainer {
  position: absolute;
  right: 0;
  width: 250px;
  background-color: white;
  padding: 7px 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, .2);
  text-align: left;
  z-index: 1;
}

.expandContainer {
  position: relative;

  .caret {
    margin-left: 10px;
  }
}

.button {
  width: 100%;
}
