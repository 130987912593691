.addImageContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;

  span {
    align-self: flex-start;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
  }

  .blankImageIcon {
    margin-top: 20px;
    font-size: 170px;
    color: #e4e4e4;
  }

  img {
    width: 300px;
    height: 260px;
    margin-bottom: 5px;
    object-fit: contain
  }

  button {
    margin-top: auto;
    margin-bottom: 5px;
  }
}

.imageChoiceCardHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  i {
    color: #c43434;
    font-size: 25px;
  }
}

.modalsWrapper {
  margin: 10px 0 5px;
  display: flex;
  flex-direction: row;
}
