.dragAndDropQuestionContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
}

.answerKeyStyles {
  margin-top: 25px;
  max-height: calc(100% - 25px);
}
