@import "../../variables";

.filter {
  margin-bottom: $margin_small;
}

.divider {
  height: 0;
  margin: 0 0-$margin_medium;
  border: $border_medium;
}
