@import '~common/colors';

.picker {
  width: 180px;
  border-radius: 4px;
  border: 1px solid $med-grey;
  line-height: 32px;
  font-size: 1em;
  margin-right: 5px;
  padding: 0 10px;
}

.pickerContainer {
  display: flex;
}

.hasError {
  border-color: red;
}

.editStartDate {
  padding: 0;
  margin-left: 5px;
  line-height: 1.2;
}

.notice {
  color: #3c3c3c;
  margin: 5px 0 10px 0;
}

.warning {
  color: $orange;
}
