.toggle-image-form {
  margin-top: 5px;
  font-size: 13px;
  position: relative;
  text-align: left;

  div.row-container {
    height: 60vh;
    overflow-y: auto;
  }
}

.image-list-container {
  width: 100%;
  background-color: #ddd;
  height: 350px;
  overflow-y: scroll;
}

.image-list-item {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #555;
  display: flex;
  z-index: 100000;

  &:last-child {
    border-bottom: 1px solid #555;
  }
}

.position {
  width: 45px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
}

.title {
  width: 200px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.zoomable {
  width: 75px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
}

.drag-handle {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  font-size: 20px;
  color: #555;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  flex-direction: column;
  margin-left: 0;
  user-select: none;

  &:active {
    cursor: grabbing;
  }

  &:hover {
    background-color: #fafafa;
  }
}

.thumb-container {
  height: 65px;
  width: 65px;
  overflow: hidden;
  margin-left: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.attach-file-container {
  height: 35px;
  width: 100%;
  display: flex;

  input {
    display: block;
    margin: auto;
    width: 200px;
  }
}

.dragging-li-class {
  background-color: #f00;
}

.image-list-item-actions {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  color: #555;
  cursor: pointer;
  flex-direction: column;
  margin-left: auto;

  &:hover {
    background-color: #fafafa;
  }
}
