@import '../../abstracts/variables';

.slide {
  background: $white no-repeat;
  min-height: 474px;
  min-width: 842px;
  position: relative;

  iframe:only-child:not(:global(.textObjectYoutubeVideo)):not(:global(.phet_sim))  {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.present {
  height: 474px;
  left: 50%;
  top: 50%;
  width: 842px;
}

.thumbnail {
  width: 150px;
  height: 100px;
  background: $white;
  left: 39%;
  top: 48%;

  &.selected {
    border: 18px solid $slideFocusActiveColor;
  }
}

.slide, .thumbnail {
  background-repeat: no-repeat;
  background-size: cover;
}

.slideTemplateOption {
  min-height: 198px !important;
  min-width: 344px !important;
}

.modalSlide {
  min-height: 100px !important;
  min-width: 150px !important;
}

.slideTemplateObject {
  position: absolute;
  height: 100%;
  width: 100%;

  &.inBuilder {
    border: 1px $dark-grey dashed;
  }
}

.slideObjectStatusIconWrapper {
  position: absolute;
  top: 0;
  left: -9px;
  z-index: 1;
}

.slideObject {
  background: transparent;
  padding: 0;
  overflow: auto;

  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.inBuilder {
    border: 1px $dark-grey dashed;
  }

  hr {
    margin-top: 0.5em;
    margin-bottom:0.5em
  }
}

.slideObject:hover button:not(:disabled) {
  opacity: 100%;
}

.slideTemplateObjectContent {
  min-width: 50px;
}

.textEditContainer {
  height: 100%;
  overflow: auto;
  width: 100%;

  .froalaEditor {
    width: 100%;
    height: 100%;
  }
}

.htmlContainer {
  height: 100%;
  width: 100%;
}

.slideObjectImageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slideObjectImage, :global(.imageInTextObject) {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

:global(td .imageInTextObject) {
  height: auto;
}

.slideObjectVideoContainer {
  display: flex;
  height: auto;
  max-height: 100%;

  /* necessary to prevent full-width videos from overflowing in fullscreen mode */
  max-width: 99.98%;
}

.backgroundPositionItem {
  position: relative;
}

.teacherNotes {
  &Icon {
    background-color: $orange;
    color: $white;
    border: none;
    right: 0;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    width: 35px;
    height: 35px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    transform: translate3d(0,0,0);
  }

  &Header {
    background-color: $orange;
    color: $white;
    font-size: 1em;

    h2 {
      font-size: 1em;
      padding-bottom: 0;
    }
  }

  &Drawer {
    padding: 0 !important;
    z-index: 2;
  }

  &Content, &Header {
    padding: .25em .5em;
  }

  &Text {
    padding-bottom: 30px;
  }
}

.defaultCursor {
  cursor: default !important;
}
