@import '~common/colors';

.label {
  width: 80px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.textInput {
  width: 87%;
  border-radius: 4px;
  border: 1px solid $med-grey;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: 12px;
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.fieldContainer {
  display: flex;
  align-items: center;
}

.fieldError {
  color: $red;
  margin-left: 86px;
  margin-top: 5px;
}

.input {
  width: 89%;
}

.textarea {
  height: 100px;
}

.setUpModal {
  li, p {
    line-height: 1.2em;
  }
}

.indentedList {
  li {
    padding-left: 16px;
  }
}

.connectionBanner {
  display: flex;
  justify-content: center;
  align-items: center;
}
