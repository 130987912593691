.decorative {
  display: flex;
  align-items: center;
}

.confidenceField {
  width: 175px !important;
}

.floatRight {
  float: right;
}
