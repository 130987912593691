.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  width: 130px;
  margin-right: 10px;
  overflow: visible;
  font-weight: bold;
}

.clearButton {
  margin-left: auto;
}
