@import '~common/colors';

.border {
  border: 2px solid $orange;
  padding: 20px 0;
  position: relative;
}

.label {
  position: absolute;
  top: 0;
  left: 15px;
  transform: translate(0, -50%);
  background-color: white;
  padding: 0 10px;
  color: $orange;
}

.primary {
  font-weight: bold;
  font-size: 16px;
}

.secondary {
  font-weight: lighter;
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
}

.itemWrapper {
  outline: none;
  cursor: pointer;
}

.menuItem {
  margin: 0 20px;
  padding: 15px 5px;
  background-color: white;
  border: none;
  font-family: Lato, Helvetica, Arial, clean, sans-serif;
  color: #3c3c3c;
  cursor: pointer;

  &.active {
    color: $tci-purple;
    font-weight: bold;
    border-bottom: 2px solid $tci-purple;
  }

  &.active:focus {
    outline: 0;
  }

  &:active:focus {
    outline: 0;
  }
}

.itemWrapper:hover .menuItem {
  border-bottom: 2px solid $tci-purple;
}

.horizontalMenu {
  border-bottom: 1px solid #eee;
}

.html {
  padding: 20px 25px;
  line-height: 130%;

  li {
    margin: 5px 0;
  }
}

.hidden {
  max-height: 160px;
  overflow: hidden;
}

.resourcesContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resourcesContainer {
  align-self: stretch;
}

.planningActions {
  padding: 5px 8px !important;

  i { font-size: 20px; }
}

.planningCollapsed {
  .html { display: none; }

  .resourcesContainerWrapper {
    button { display: none; }
  }

  .prevArrow, .nextArrow { margin-bottom: 0; }
}
