@import '~assets/stylesheets/abstracts/variables';

$borderRadius: 4px;

.textAction {
  border: 1px solid $med-grey;
  border-radius: $borderRadius;
  padding: 5px 10px;
  text-decoration: none;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;
  color: $tci-purple;

  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
  }
}

.ttsActionPlay {
  @extend .textAction;

  border-radius: $borderRadius 0 0 $borderRadius;
  margin-right: 0;
  border-right: none;
}

.ttsActionDropdown {
  @extend .textAction;

  border-radius: 0 $borderRadius $borderRadius 0;
  border-left: 1px solid $med-grey;
  border-right: 1px solid $med-grey;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  margin-right: 5px;
  padding: 0 4px 0 4px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $light-grey !important;
  }
}

.ttsSpeedButton {
  width: 100%;

  .ttsSpeedSelectText {
    color: $correct !important;
    margin-right: 4px;
  }

  &:focus {
    outline: none;
  }
}

.loading, .buttonSize {
  height: 37px;
  width: 41px;
}
