@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.button {
  border: none;
  padding: 0;
  background-color: white;
}

.tooltipContainer { position: relative; }

.triangle {
  position: absolute;
  left: 50%;
  height: 0;
  width: 0;
  transform: translate(-50%, 0);
  border: 7px solid transparent;
}

.tooltip {
  position: absolute;
  background-color: white;
  padding: 10px 13px;
  border-radius: 7px;
  font-size: 22px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  z-index: 1000;
  display: flex;
  align-items: center;

  button { background-color: white; }

  &.desktop {
    transform: translate(-50%, calc(-100% - 15px));

    .triangle {
      top: 100%;
      border-top: 7px solid white;
    }
  }

  &.mobile {
    transform: translate(-50%, 15px);
    padding: .5em .75em;

    .colorPicker, .highlightIcon {
      width: 25px;
      height: 25px;
    }

    .trash {
      font-size: 20px;
    }

    .triangle {
      bottom: 100%;
      border-bottom: 7px solid white;
    }
  }
}

.buffer { font-size: 0; }

.highlightIcon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 5px;

  &.green { background-image: url('./marker-green.svg'); }
  &.blue { background-image: url('./marker-blue.svg'); }
  &.orange { background-image: url('./marker-orange.svg'); }
  &.rose { background-image: url('./marker-rose.svg'); }
  &.yellow { background-image: url('./marker-yellow.svg'); }
  &.purple { background-image: url('./marker-purple.svg'); }
}

@mixin background-colors {
  &.green { background-color: $highlight-green; }
  &.blue { background-color: $highlight-blue; }
  &.orange { background-color: $highlight-orange; }
  &.rose { background-color: $highlight-rose; }
  &.yellow { background-color: $highlight-yellow; }
  &.purple { background-color: $highlight-purple; }
}

.colorPicker {
  @include background-colors;

  width: 19px;
  height: 19px;
  border-radius: 2px;
  border: 1px solid #676767;
  margin-left: 5px;
  cursor: pointer;
}

.highlight {
  @include background-colors;

  &.invisible {
    background-color: transparent;
    cursor: unset;
  }
}

.saved { cursor: pointer; }

.trash {
  color: $very-dark-grey;
  cursor: pointer;
  font-size: 22px;
  margin-left: 10px;

  &:hover { color: $red; }
}
