.footer {
  position: absolute;
  width: 98%;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #fff;
}

.listContainer {
  margin-bottom: 60px;
}
