.selector-wrapper {
  height: 400px;
  width: 100%;
}

.images-container {
  display: flex;
  width: 100%;
  height: 325px;
  overflow-y: scroll;
  flex-flow: row wrap;
  justify-content: flex-start;

  figure {
    word-break: break-all;
    display: flex;
    flex-flow: column wrap;
    width: 25%;
    height: 150px;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;

    & > div {
      width: 100px;
      height: 100px;
      text-align: center;
      align-items: center;
    }

    img {
      max-width: 100px;
      max-height: 100px;
      cursor: pointer;
      border: 2px solid #fff;

      &:hover {
        border-color: #55a;
      }
    }
  }

  figcaption {
    font-size: 12px;
    width: 100px;
    text-align: center;
    padding: 3px 0;
    height: 40px;
    line-height: 15px;
  }
}

.images-container figure .selected-image {
  border-color: #55a;
}
