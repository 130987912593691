.programsForm {
  padding: 5px;

  .programRow {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    margin: 10px;
  }

  .programCode {
    font-weight: bold;
    padding-left: 20px;
    padding-right: 5px;
  }
}
