@import '~assets/stylesheets/abstracts/variables';

.field {
  border-radius: 4px;
  border: 1px solid $med-grey;
  font-size: 1em;
  padding: 10px;
}

.notesField {
  height: 100px;
  width: 100%;
  font-family: $font-family-base;

  .notesRowAlignment {
    align-items: normal;
  }
}

.exchangeProgramDropdown {
  width: 100%;
}

.warnings li {
  line-height: 140%;
  margin: 20px 0;
}

.proceedInput {
  padding: 0 10px;
  line-height: 32px;
  width: 50%;
}
