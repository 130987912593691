.dropZoneArea {
  display: flex;
  background: white;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 2;
  position: relative;
}

.whiteBackground {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: -100000;
}

.horizontalDropZoneArea {
  background: transparent !important;
  background-size: contain;
  margin: 0 auto;
}

.posAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
}
