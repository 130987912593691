@import '~common/colors';

.form {
  margin-top: 5px;
  font-size: 13px;
  position: relative;
  text-align: left;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.label {
  line-height: 1.1em;
  font-weight: bold;
  white-space: initial;
  width: 100%;
}

.labelText {
  width: 20%;
  display: inline-block;
}

.textInput {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid $med-grey;
  width: 70%;
  font-size: 100%;
}
