@import '../colors.scss';

.container {
  display: flex;
  margin: -15px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  color: $white;
  width: 50px;
  min-height: 55px;
  flex-shrink: 0;
}

.success {
  background-color: $green;
}

.error {
  background-color: $red;
}

.info {
  background-color: $blue;
}

.warning {
  background-color: $orange;
}

.messageContainer {
  color: $very-dark-grey;
  padding: 15px 25px 15px 15px;
  display: flex;
  align-items: center;
  line-height: 1.2em;
}

.heading {
  font-weight: bold;
  margin-bottom: 5px;
}
