.integrationsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .lineBreak {
    width: 100%;
  }

  .integrationsFooter {
    display: flex;
    height: 20px;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}
