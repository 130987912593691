@import '~common/colors';

.errorsCountContainer {
  padding-bottom: 20px;
  border-bottom: 1px solid $med-grey;
  display: flex;
  align-items: center;
}

.errorsTableContainer {
  width: 500px;
  text-align: right;
}

.exclamation {
  color: $red;
  font-size: 2rem;
  padding-right: 15px;
}
