.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  width: 105px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.select {
  width: 90%;
}
