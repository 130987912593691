.pagingContainer {
  display: flex;
  justify-content: space-between;
}

.pagingButton {
  padding: 9px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
}

.smallPagingButton {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: auto 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
}

span.pagingButton {
  color: grey;
  cursor: default;
}

span.smallPagingButton {
  color: grey;
  cursor: default;
}

.positionContainer {
  margin: auto 10px;
}

.pagingButtonContent {
  display: flex;
  align-items: center;
  width: 70px;
  line-height: 14px;
}
