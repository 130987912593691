.container {
  height: 100%;
}

.noOtherCopyright {
  display: flex;
  align-items: center;
  height: 100%;
}

.sectionContainer {
  margin-top: 30px;

  .programSelector {
    width: 75%;
  }

  .radioButtonsContainer {
    display: flex;

    label {
      display: flex;
      align-items: center;
      overflow: visible;

      span {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }
}
