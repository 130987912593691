//
// Utilities
//

// Text
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }

  a#{$parent} {
    &:hover,
    &:focus {
      color: darken($color, 10%) !important;
    }
  }
}


// Lists
// Display `<ul>` and `<ol>` lists properly with bullets and numbers
@mixin lists {
  ul > li { list-style: disc; }

  ol > li { list-style: decimal; }

  ul, ol {
    padding-left: 1.25rem;
    list-style-position: outside;
    ul > li { list-style-type: circle; }
  }
}


// Icons
@mixin square-size($length) {
  width: $length;
  height: $length;
  line-height: $length;
}

@mixin bg-circle-filled($color, $length) {
  @include square-size($length);

  color: color-yiq($color);
  background-color: $color;
  text-align: center;
  border-radius: 50%;
}

// Cursor
@mixin cursor-grab {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: image-url(grab) !important; /* fallback: Internet Explorer */
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important;
  user-select: none !important;
}

@mixin cursor-grabbing {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: image-url(grabbing) !important; /* fallback: Internet Explorer */
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
  user-select: none !important;
}

//
// Buttons
//
@mixin button-variant(
  $background,
  $border,
  $box-shadow-color: darken($background, 7.5%),
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 7.5%),
  $hover-box-shadow-color: darken($hover-background, 7.5%),
  $active-background: darken($hover-background, 10%),
  $active-border: darken($hover-background, 10%)
) {
  color: color-yiq($background);
  background-color: $background;
  border-color: $border;

  &:before {
    color: color-yiq($background);
  }

  &:focus,
  &.focus,
  &:hover {
    color: color-yiq($hover-background);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
  }

  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    background-image: none; // Remove the gradient for the pressed/active state
    border-color: $active-border;

    &:focus,
    &.focus {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    }
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  color: $color;
  background-color: $white;
  background-image: none;
  border-color: $color;
  box-shadow: none;

  &:hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
    box-shadow: none;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }
}

@mixin button-link-variant(
  $color,
  $color-hover: darken($color, 15%)
) {
  color: $color;
  box-shadow: none;
  font-weight: $font-weight-normal;
  background: transparent;

  &:hover,
  &.hover {
    color: $color-hover;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    color: $color-hover;
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;

    &:focus,
    &.focus {
      box-shadow: none;
    }
  }
}

@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}


//
// Carets
//
@mixin caret-down {
  border-top: $caret-width solid;
  border-right: $caret-width solid transparent;
  border-bottom: 0;
  border-left: $caret-width solid transparent;
}

@mixin caret-up {
  border-top: 0;
  border-right: $caret-width solid transparent;
  border-bottom: $caret-width solid;
  border-left: $caret-width solid transparent;
}

@mixin caret-right {
  border-top: $caret-width solid transparent;
  border-right: 0;
  border-bottom: $caret-width solid transparent;
  border-left: $caret-width solid;
}

@mixin caret-left {
  border-top: $caret-width solid transparent;
  border-right: $caret-width solid;
  border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: $caret-width * .85;
    vertical-align: $caret-width * .85;
    content: "";

    @if $direction == down {
      @include caret-down;
    }
    @else if $direction == up {
      @include caret-up;
    }
    @else if $direction == right {
      @include caret-right;
    }
    @else if $direction == end {
      @include caret-down;
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }

  @if $direction == left {
    &::after {
      display: none;
    }

    &::before {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: $caret-width * .85;
      vertical-align: $caret-width * .85;
      content: "";
      @include caret-left;
    }
  }

  &:empty::after {
    margin-left: 0;
  }
}


// TODO: Sort mixins from before

@mixin action-link {
  display: inline-block;
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 12px;
  text-decoration: none;
  color: $tci-purple;
  background-color: $light-grey;
  border-top-color: #ddd;
  border-left-color: #ddd;
  white-space: nowrap;
  line-height: 1em;
  cursor: pointer;

  .tooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid #aaa;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, .2);
    color: #333;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    font-family: $font-family-base;
    text-align: center;
  }

  &:hover {
    color: $light-grey;
    background: $link-purple;
    text-decoration: none;

    .tooltip {
      display: block;
      right: 0;
      top: -4em;
      width: auto;
    }
  }

  &.disabled, &:disabled {
    color: #666;

    &:hover {
      position: relative;
      cursor: not-allowed;
      background: $light-grey;
    }
  }
}

// Slideshow

@mixin teacher_assessment_question {
  ul {
    margin-left: 30px;

    li {
      border: none;
      border-bottom: 1px dotted #aaa;
      border-width: 1px;
      padding: 5px;
      margin-bottom: 0;
      margin-left: 10px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

/////////////////////////////////////////////////////
// Basic Reading Challenge Mixin
////////////////////////////////////////////////////
@mixin challenge-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.incorrect_answered {
    background-position: $incorrect-bg-pos;
    pointer-events: none;
  }
}

/////////////////////////////////////////////////////
// Geographer's Journey
////////////////////////////////////////////////////
@mixin gj-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.gj_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.gj_incorrect_answered {
    background-position: $incorrect-bg-pos;
    pointer-events: none;
  }
}

/////////////////////////////////////////////////////
// A Curious State
////////////////////////////////////////////////////
@mixin acs-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.acs_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.acs_incorrect_answered { background-position: $incorrect-bg-pos; }
}

/////////////////////////////////////////////////////
// Budding Wisdom
////////////////////////////////////////////////////
@mixin bw-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.bw_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.bw_incorrect_answered { background-position: $incorrect-bg-pos; }
}

/////////////////////////////////////////////////////
// CHTC & CHTCB Grid
////////////////////////////////////////////////////
@mixin chtc-grid-image(
  $paper-bg-pos,
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $inkblot-bg-pos
) {
  background-position: $paper-bg-pos;
  &::before { background-position: $num-bg-pos; }
  &:hover::before { background-position: $hvr-num-bg-pos; }

  &.chtc_challenge_active,
  &.chtcb_challenge_active {
    &::before { background-position: $tryagain-bg-pos; }
    &:hover::before { background-position: $hvr-tryagain-bg-pos; }
  }

  &.chtc_incorrect_answered::before,
  &.chtcb_incorrect_answered::before { background-position: $inkblot-bg-pos; }
}

/////////////////////////////////////////////////////
// Librarians Quest
////////////////////////////////////////////////////
@mixin lq-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.lq_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.lq_incorrect_answered { background-position: $incorrect-bg-pos; }
}

@mixin rq-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.rq_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.rq_incorrect_answered { background-position: $incorrect-bg-pos; }
}

@mixin sr-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.sr_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.sr_incorrect_answered { background-position: $incorrect-bg-pos; }
}


@mixin uk-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos,
  $incorrect-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.uk.challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }

  &.uk_incorrect_answered { background-position: $incorrect-bg-pos; }
}

@mixin ie-front-grid-image(
  $num-bg-pos,
  $hvr-num-bg-pos,
  $tryagain-bg-pos,
  $hvr-tryagain-bg-pos
) {
  background-position: $num-bg-pos;
  &:hover { background-position: $hvr-num-bg-pos; }

  &.ie_challenge_active {
    background-position: $tryagain-bg-pos;
    &:hover { background-position: $hvr-tryagain-bg-pos; }
  }
}

@mixin ie-back-grid-image(
  $correct-bg-pos,
  $incorrect-bg-pos
) {
  &.ie_incorrect_answered { background-position: $incorrect-bg-pos; }
  &.ie_answered { background-position: $correct-bg-pos; }
}

%default-paragraph {
  font-family: $font-family-base;
  font-size: 14px;
  line-height: 1.43;
  color: $dark-grey;
}

// vertical and horizontal center
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%inline-flex-center {
  @extend %flex-center;
  display: inline-flex;
}

%hint-button {
  cursor: pointer;
  color: $tci-purple;
  font-size: 17px;

  @each $color, $value in $theme-colors {
    &[data-background-color="#{$color}"] {
      color: $value;

      &:before {
        color: $value;
      }
    }
  }
}

%elem-question-backgrounds {
  &.swirl .question-interactable {
    background-image: image-url('notebook_patterns/swirl.png');
    background-size: 500px;
  }

  &.stripes .question-interactable {
    background-image: image-url('notebook_patterns/stripes.png');
    background-size: 100px;
  }

  &.cube .question-interactable {
    background-image: image-url('notebook_patterns/cube.png');
    background-size: 100px;
  }
}
