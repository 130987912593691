@import '~assets/stylesheets/abstracts/variables';

.fr-box {
  width: 100%;
  height: 100%;
}

.fr-wrapper {
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;

  .fr-placeholder {
    min-width: 50px;
  }
}

.fr-view {
  margin: 1px;
  width: 100%;
  height: 100%;

  img.fr-dii, .image-zoom-icon-wrapper img.fr-dii {
    margin: 0;
    // Froala adds margin left and right of 5px, and so adds `max-width: calc(100% -(2* 5px));`
    // By overriding margin, we also need to override that max-width.
    max-width: 100%;
  }
}

.fr-quick-insert {
  display: none;
}

.fr-second-toolbar {
  display: none;
}

// override defaults from froala css for the slides
.current-slide {
  position: relative;

  .fr-box.fr-basic {
    .fr-element:not(.hotspot-content) {
      font-family: $font-family-sans-serif !important;
      color: #555 !important;
      font-style: normal !important;
      line-height: 1.2em !important;
      font-size: 16px !important;
      overflow-wrap: break-word !important;
      padding: 0 !important;
    }
  }
}

#froala-editor-toolbar {
  position: absolute;
  top: 0;
  z-index: 10;
  max-width: 800px;

  .fr-toolbar {
    border-radius: 4px !important;
  }
}
