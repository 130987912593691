.modalContent {
  text-align: center;
  padding: 10px;
}

.currentPdf {
  margin-bottom: 20px;
}

.button {
  display: block;
  margin-bottom: 5px;
}

.updatePdf {
  text-align: center;
}
