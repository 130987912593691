$container-width: 230px;
$height: 26px;
$button-width: 36px;
$border-width: 1px;
$border-radius: 4px;
$border-color: #aaa;
$background-color: #fff;
$font-size-percentage: 93%;
$text-color: #222;
$padding: 4px;

.textfield-icon-button {
  font-size: $font-size-percentage;
  box-sizing: border-box;
  width: $container-width;
  display: flex;
  align-items: stretch;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  height: $height;
  overflow: hidden;
  color: $text-color;
  background: $background-color !important;

  input {
    border: none;
    box-sizing: border-box;
    padding: $padding;
    background: $background-color !important;
    flex-grow: 1;
  }

  button {
    width: $button-width;
    border-radius: 0 $border-radius $border-radius 0 !important;
    margin-left: auto;
    border: none;
    border-left: $border-width solid $border-color;
    padding: 0;
    color: $text-color;
    flex-shrink: 0;
  }
}
