@import '~assets/stylesheets/abstracts/variables';

.dropdown-menu.dropdownLink {
  min-width: 160px;
}

.dropdownLink {
  max-width: 300px;
}

a.dropdownLink {
  white-space: pre-wrap;
}

.button {
  font-size: .95em;
  padding: .1rem .75rem;
}

.correct, .correct:disabled {
  border-color: $green;
}

.incorrect, .incorrect:disabled {
  border-color: $red;
}
