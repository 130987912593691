.form-row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 15px;

  textarea, input[type="text"], input[type="number"], select {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 70%;
    font-size: 100%;
  }

  label {
    line-height: 1.1em;
    width: 20%;
    font-weight: bold;
    white-space: initial;
  }
}

label.checkbox-label {
  width: 100%;
}

