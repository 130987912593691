.slideObjectMapOverlay {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .interactiveMapOpenMapButton {
    font-size: 1.25rem;
    padding: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-height: 90%;
    max-width: 14rem;
    margin: 1rem;

    img {
      width: 2.5rem;
      margin: 0;
    }

    p {
      white-space: wrap;
      margin: 0 0 0 1rem;
    }
  }
}

.slideObjectInteractiveMapFrame {
  border: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
