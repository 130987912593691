.container {
  padding: 20px;
}

.label {
  display: block;
  padding: 5px;

  input {
    margin: -1px 5px 0 0;
    vertical-align: middle;
  }
}

.shareQuestionContainer {
  button {
    margin-left: 20px;
  }

  h4 {
    margin-bottom: 15px;
    padding: 0;
  }

  i {
    margin-left: 5px;
  }
}
