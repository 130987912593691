.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.fieldContainer {
  display: flex;
  align-items: center;
}

.label {
  width: 130px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.radioInput {
  vertical-align: initial;
}

.radioLabel {
  margin-right: 15px;
}

.textInput {
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  width: 330px;
  height: 36px;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.fieldError {
  color: red;
  margin-left: 140px;
  margin-top: 5px;
}

.red {
  color: red;
}

.checkboxLabel {
  padding-bottom: 2px;
  overflow: visible;
}
