@import '~assets/stylesheets/abstracts/variables';

.icon {
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 1000;
  height: 20px;
  width: 20px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 5.8px 5.8px rgba(0, 0, 0, .25);
  font-size: 14px;

  .iconChild {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    &.correct {
      background-color: $green;
    }

    &.partiallyCorrect {
      background-color: #fcc521;
    }

    &.incorrect {
      background-color: $red;
    }
  }
}
