@import '~assets/stylesheets/abstracts/variables';

.dropdown {
  z-index: $zindex-fixed;
  position: absolute;
  top: 43px;
  right: -39px;
  width: 400px;
  border: 1px solid lightgrey;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 $light-grey;
}

.dropdown::before {
  position: absolute;
  top: -17px;
  right: 58px;
  content: "";
  border: 9px solid transparent;
  border-bottom-color: #f7f7f7;
}

.header {
  padding: 1rem;
  background-color: #fafafa;
  line-height: initial;
  border-bottom: 1px solid lightgrey;
  color: $dark-grey;

  h1 {
    color: $black;
    font-size: 20px;
  }
}

.messagesContainer {
  position: relative;
  overflow-y: scroll;
  max-height: 350px;
  color: $dark-grey;
  background: $white;

  &.loading {
    min-height: 350px;
  }
}

.message {
  padding: 1rem 1em 1em 2em;
  font-size: 14px;
  line-height: initial;
}

.empty {
  text-align: center;
}

.message:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.helpCenterButton {
  margin-top: 10px;
}

.list {
  margin-left: 2em;
  list-style: disc;
}

.needsGrading {
  font-weight: bold;
  color: $green;
}

.date {
  line-height: normal;
  padding: 5px 5px 5px 15px;
  border-bottom: 1px solid lightgrey;
  font-weight: bold;
  color: $very-dark-grey;
  display: flex;
  align-items: center;
}
