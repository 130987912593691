@import '~assets/stylesheets/abstracts/variables';

.messagesContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

div.message {
  width: 500px;
  padding: 1em;
  margin: 1em 0;
  text-align: inherit;
  position: relative;
}

div.selected {
  outline: 1px solid $tci-purple !important;
}

span.selectedText {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
