@import '~assets/stylesheets/abstracts/variables';

.textArea {
  font-family: $font-family-sans-serif;
}

.modalContent {
  min-height: 160px;
}

.hidden {
  display: none;
}
