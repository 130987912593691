@import "../../colors";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  span {
    flex-grow: 1;
    font-size: inherit;
  }
}

.checkbox {
  flex-shrink: 0;
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid $med-grey;
  border-radius: 3px;
  margin: 0 5px 0 0;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 200ms cubic-bezier(0.1, 0.1, 0.25, 1);
  background: $white;

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 3px;
    width: 3px;
    height: 7px;
    border: solid;
    border-color: $white;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    opacity: 0;
  }

  &:checked {
    color: $white;
    border-color: $dark;
    background: $dark;
    &::before {
      opacity: 1;
    }
  }

  &:indeterminate {
    color: $white;
    border-color: $dark;
    background: $dark;

    &::before {
      transform: rotate(0);
      border-width: 0 0 2px 0;
      opacity: 1;
      height: 4px;
      width: 5px;
    }
  }
}
