.iconWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #247F99;
  border-radius: 50%;
  margin-right: 4px;
}
