.modal {
  .selectContainer {
    margin-bottom: 25px;

    label {
      margin-right: 5px;
      width: 13%;
    }
    .select {
      display: block;
    }
  }
}

.footer {
  overflow: hidden;
  padding: 0 10px 10px 0;
}

.checkboxGroup {
  .individualCheckboxes {
    padding-left: 10px;
  }

  label { margin-bottom: 10px; }
}
