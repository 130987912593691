@import '~assets/stylesheets/abstracts/variables';
@import '~assets/stylesheets/abstracts/mixins';

.choicesBankItem {
  flex-shrink: 0;
  height: 90%;

  .draggableChoice {
    height: 100%;
    margin: 0 20px;
    padding: 3px;

    .content {
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: auto !important;
        max-width: unset;
        padding: 0;
      }
    }
  }
}

.textChoicesBankItem {
  height: min-content;

  .draggableChoice {
    padding: .5rem;
  }
}

.draggedChoiceContainer {
  width: 100%;
  height: 100%;
  position: relative;

  .draggableChoice {
    flex-basis: 100%;
    margin: 0;

    .content img {
      height: auto !important;
    }
  }
}

.customDragLayer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.draggableChoice {
  box-sizing: border-box;
  position: relative;
  border: 1px solid #ccc;
  padding: 0.5rem;
  background-color: #fff;
  list-style: disc !important;
  width: fit-content;
  margin: 5px;

  @include cursor-grab;

  &.transparentBackground {
    background: transparent;
    border: none;
  }

  .content {
    font-size: 14px;
  }

  img {
    @include cursor-grab;

    &:active {
      @include cursor-grabbing;
    }
  }

  &.answered {
    min-width: auto;

    &.updatedQuestionScoring {
      &.correct {
        border: 1px solid #2A8806;
      }

      &.incorrect {
        border: 1px solid #D2232A;
      }
    }
  }

  ul {
    list-style: unset !important;
  }

  &:active {
    @include cursor-grabbing;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.rectangle {
  border-radius: 5px;
}

.circle {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
}
