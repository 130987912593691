@import '~assets/stylesheets/abstracts/variables';
@import '~assets/stylesheets/abstracts/functions';
@import '~assets/stylesheets/abstracts/mixins';
@import 'stylesheets/components/buttons';

%html-element-base {
  @extend .btn;

  @each $color, $value in $theme-colors {
    &[data-background-color="#{$color}"] {
      @extend .btn--#{$color};
      white-space: normal;
    }
  }
}

[data-html-element-type="JumpToButton"] {
  &[data-display-as-link="true"] {
    cursor: pointer;
    display: inline;
    padding: 0;
    border: none;
    background: none;
    font-size: inherit;
    font-weight: bold;
    text-decoration: underline;

    @each $color, $value in $theme-colors {
      &[data-background-color="#{$color}"] {
        color: $value;
      }
    }

    &:hover {
      color: $link-hover-color;
    }
  }

  &:not([data-display-as-link="true"]) {
    @extend .btn--purple;
    @extend %html-element-base;
  }
}
[data-html-element-type="TooltipButton"],
[data-html-element-type="VideoButton"] {
  @extend .btn--purple;
  @extend %html-element-base;
}

[data-html-element-type="QuestionButton"] {
  @extend .btn--blue;
  @extend %html-element-base;
}

[data-html-element-type="ReadingButton"] {
  @extend .btn--green;
  @extend %html-element-base;
}

[data-html-element-type="HintButton"] {
  @extend %hint-button;
}

.locale {
  &-en {
    [data-html-element-type="QuestionButton"],
    [data-html-element-type="JumpToButton"] {
      &:after {
        content: attr(data-button-text-en)
      }
    }
    [data-html-element-type="TooltipButton"],
    [data-html-element-type="VideoButton"],
    [data-html-element-type="ReadingButton"] {
      &:after {
        content: attr(data-button-text-en)
      }
    }
  }

  &-es {
    [data-html-element-type="QuestionButton"],
    [data-html-element-type="JumpToButton"] {
      &:after {
        content: attr(data-button-text-es)
      }
    }
    [data-html-element-type="TooltipButton"],
    [data-html-element-type="VideoButton"],
    [data-html-element-type="ReadingButton"] {
      &:after {
        content: attr(data-button-text-es)
      }
    }
  }
}
