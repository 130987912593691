.modal {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.modalPopup {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 95%;
  max-height: 100%;
  width: 95%;

  .spinner { margin: auto; }
}
