@import '~assets/stylesheets/abstracts/variables';

.tableTooltip {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  margin-bottom: 5px;
  padding: 4px 8px;
}

.tableTooltip span {
  font-family: $font-family-sans-serif;
  padding-left: 5px;
}
