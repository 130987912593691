.header {
  display: flex;
  margin-bottom: 5px;
}

.propertySelect {
  width: 200px;
}

.operatorSelect {
  width: 200px;
}

.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.datePicker {
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 32px;
  width: 187px;
  background-color: #fff !important;
  font-size: 13px;
  padding-left: 10px;
}

.datePickWrapper {
  display: flex;
}

.calendarIcon {
  width: 15px;
  height: 17px;
  margin: auto;
  padding-left: 5px;
}

.deleteFilterRowButton {
  display: block;
  color: #d2232a;
  font-size: 1.3rem;
  cursor: pointer !important;
  background-color: white;
  border: none;
}
