$red:             #d2232a;
$orange:          #f69c05;
$green:           #2A8806;
$blue:            #2b9ab8;
$tci-purple:      #522e91;

$white:           #fff;
$light-grey:      #eee;
$med-grey:        #ccc;
$dark-grey:       #777770;
$very-dark-grey:  #555;
$black:           #000;
