@import '~common/colors';

.label {
  width: 80px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
}

.textInput {
  width: 87%;
  border-radius: 4px;
  border: 1px solid $med-grey;
  color: #333;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;
  height: auto;
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.fieldContainer {
  display: flex;
  align-items: center;
}
