// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming (e.g. $nav-link-disabled-color).


//
// Colors
//
$red:             #d2232a;
$orange:          #f69c05;
$green:           #2A8806;
$blue:            #247f99; // #2b9ab8; // #3fa0b2; (isn)
$tci-purple:      #522e91;

$white:           #fff;
$light-grey:      #eee;
$med-grey:        #ccc;
$dark-grey:       #777770;
$very-dark-grey:  #555;
$black:           #000;

$slideFocusActiveColor: #3FA2F7; // used in slide builder slide panel

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "red":            $red,
    "orange":         $orange,
    "green":          $green,
    "blue":           $blue,
    "purple":         $tci-purple,
    "white":          $white,
    "light-grey":     $light-grey,
    "med-grey":       $med-grey,
    "dark-grey":      $dark-grey,
    "very-dark-grey": $very-dark-grey,
    "black":          $black
  ),
  $theme-colors
);

// This can be used for the CSS `filter` property to generate SVGs of green #2A8806.
// Generated using https://isotropic.co/tool/hex-color-to-css-filter/
$svg-green: invert(38%) sepia(98%) saturate(3510%) hue-rotate(76deg) brightness(94%) contrast(95%);

// The yiq lightness value that determines when the lightness of color changes
// from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  170 !default;

// Customize light and dark text colors for use in YIQ color contrast function.
$yiq-text-dark:             $black !default;
$yiq-text-light:            $white !default;

// TODO: consolidate colors below
$border-gray:     #dedede;
$base_grey:       #ddd;
$left_side_color: #e0e0e0;
$light-yellow:    #fcf8e3;
$tci-green:       #b0b028;
$correct:         #78a22f;
$light-purple:    lighten($tci-purple, 50%);
$link-purple:     #583597;

// Tentative NGSS colors
$ngss-orange:     $orange;
$ngss-green:      $tci-green;
$ngss-blue:       #43449b;

// Highlight colors
$highlight-green:   #b4f106;
$highlight-blue:    #11ded4;
$highlight-orange:  #ffaf19;
$highlight-rose:    #f8877f;
$highlight-yellow:  #ffdf65;
$highlight-purple:  #EA89EC;

//
// Spacing
//
$spacer:                      1rem !default;


// Body
//
// Settings for the `<body>` element.
$body-bg:                     $white !default;
$body-color:                  $very-dark-grey !default;


// Links
//
// Style anchor elements.

$link-color:                  $tci-purple !default;
$link-decoration:             none !default;
$link-hover-color:            darken($link-color, 15%) !default;
$link-hover-decoration:       underline !default;


// Grid containers
//
// Set width for site.
$site-width:                  61.875rem !default; // 990px

// Margin between left nav and page contents
$content-left-margin:         40px;

// Navigation
$left-nav-width:              250px;
$left-nav-collapsed-width:    65px;

// Responsive widths
$responsive-min-width:        990px;
$responsive-max-width:        1600px;

// Components
//
// Define common padding, border radius sizes and more.
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$component-active-color:      $white !default;
$component-active-bg:         $tci-purple !default;

$caret-width:                 .3em !default;

$transition-fade:             opacity .15s linear !default;


//
// Fonts
//
$font-family-sans-serif:      Lato, Helvetica, Arial, sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-system:          -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base:            $font-family-sans-serif !default;

$bubbler-one:                 'Bubbler One', $font-family-base;
$crimson-reg:                 'Crimson Text', $font-family-base;
$questrial:                   'Questrial', $font-family-base;

$font-size-base:              .875rem !default; // 14px, based on root font size of 16px
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$small-font-size:             80% !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         .25rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .05rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


//
// Buttons
//
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-line-height:             $line-height-base !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-line-height-sm:          $line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-line-height-lg:          $line-height-lg !default;

$btn-border-width:            $border-width !default;

$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              rgba(0, 0, 0, .12) 0 1px 6px, rgba(0, 0, 0, .12) 0 1px 4px !default;

$btn-focus-width:             .2rem !default;
$btn-focus-color:             rgba($component-active-bg, .25) !default;
$btn-focus-box-shadow:        0 0 0 $btn-focus-width $btn-focus-color !default;

$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       rgba(0, 0, 0, .16) 0 3px 10px, rgba(0, 0, 0, .23) 0 3px 10px !default;

$btn-link-disabled-color:     $dark-grey !default;

$btn-block-spacing-y:         .5rem !default;

$btn-transition:              all 450ms cubic-bezier(.23, 1, .32, 1) 0ms !default;


//
// Tables
//
$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem .5rem !default;
$table-cell-padding-lg:       1.25rem .75rem !default;

$table-bg:                    transparent !default;

$table-border-width:          $border-width !default;
$table-border-color:          $light-grey !default;

$table-line-height:           $line-height-base !default;
$table-text-align:            left !default;

$table-head-bg:               $light-grey !default;
$table-head-color:            $very-dark-grey !default;

$table-striped-order:         odd !default;


// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-min-width:                10rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-divider-bg:               $light-grey !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $very-dark-grey !default;
$dropdown-link-hover-color:         darken($very-dark-grey, 5%) !default;
$dropdown-link-hover-bg:            $light-grey !default;

$dropdown-link-active-color:        $white !default;
$dropdown-link-active-bg:           $tci-purple !default;

$dropdown-link-disabled-color:      $med-grey !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $med-grey !default;


// Cards
//
// Width and spacing for default cards and card decks.
$card-deck-gutter:                  1rem !default;
$card-deck-cards:                   4 !default;
$card-width:                        ($site-width - ($card-deck-cards - 1) * $card-deck-gutter) / $card-deck-cards;

$card-box-shadow:                   0 2px 4px 0 rgba(0, 0, 0, .1) !default;
$card-hover-box-shadow:             0 5px 20px 0 rgba(0, 0, 0, .15) !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-impersonate-border:         1035 !default;
$zindex-left-nav:                   1035 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// TODO: sort these variables

// Interactions
$interaction_height: 630px;
$interaction_width: 930px;
$question_height: 75px;
$left_side_width: 289px;
$right_side_width: $interaction_width - $left_side_width;
$effective_height: $interaction_height - $question_height;
