@import '~common/colors';

.bar {
  height: 30px;
  padding: 10px 10px;
  border-top: 1px solid $light-grey;

  color: $dark-grey;
}

.actions {
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  display: flex;
}

.pacing {
  height: 30px;
  float: right;
  color: $dark-grey;
  font-style: italic;
  line-height: 30px;
}

.shareContainer {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 5px;

  a {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
  }
}
