@import '~common/colors';

.newSideNav {
  .tab {
    height: 24px;

    .label { padding: 4px 0 4px 28px; }

    &.active {
      .label { margin-left: -7px; }
    }

    .parentTab {
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  .subTab {
    min-height: 24px;

    .label { padding: 4px 15px 4px 45px; }

    .newLabel {
      padding-left: 20px;
      font-size: 11px;
      color: $orange;
      text-transform: uppercase;
    }

    &.active {
      .label { padding-left: 38px; }
    }
  }

  .tab.light, .subTab.light {
    &.active, &:active, &.active:hover {
      background-color: #7444c4;
    }
  }

  .active {
    border-left: 7px solid white;
  }
}

.tab {
  display: flex;
  cursor: pointer;
}

.label {
  display: flex;
  padding: 8px 28px;
  color: white !important;
  flex-grow: 1;
  text-decoration: none !important;
  align-items: center;
  justify-content: space-between;
}

.circle {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow {
  padding: 12px 20px 12px 12px;
  display: block;
  position: relative;

  &:hover .circle { background-color: rgba(255, 255, 255, .05); }
}

.caret {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subTab {
  .label {
    padding: 8px 15px 8px 45px;
  }

  &.active {
    .label {
      padding-left: 41px;
    }
  }
}

.active {
  font-weight: bold;
  border-left: 4px solid white;
}

.tab, .subTab {
  box-sizing: border-box;
  text-decoration: none !important;
  color: white !important;
}

.tab.light, .subTab.light {
  &:hover { background-color: #5e399e; }

  &.active, &:active, &.active:hover {
    background-color: #633fa0;
  }
}

.tab.dark, .subTab.dark {
  &:hover { background-color: #30294e; }

  &.active, &:active, &.active:hover { background-color: #1e1c33; }
}

.eye {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.sublabel {
  font-size: 12px;
  font-weight: bold;

  &:not(:empty) { margin-top: 3px; }
}

.lineSeparator {
  margin-top: 10px;
  margin-bottom: 4px;
  opacity: .2;
}
