.warningIcon {
  height: 0.8em;
  color: #f69c08;
}

.keyIcon {
  height: 0.8em;
  filter: grayscale(100%);
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
