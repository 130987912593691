.banner {
  margin-bottom: 20px;
  max-width: 700px;
}

.googleIcon {
  height: 1.5em;
  padding-left: 10px;
  vertical-align: middle;
}
