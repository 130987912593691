.tooltip {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .simpleAudioButtonImage {
    width: 30px;
  }

  .htmlAudioImage {
    width: 200px;
  }
}

.hint {
  font-size: 11px;
  margin-left: 130px;
}
