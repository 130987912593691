@import '~common/colors';

.option {
  margin: 0 5px;

  label {
    font-size: 14px;
  }
}

.header {
  font-weight: bold !important;
  color: $very-dark-grey !important;
}
