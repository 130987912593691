.loading {
  text-align: center;
  font-size: 2em !important;
}

.headerCell {
  padding: 8px 10px;
}

.empty { text-align: center; }
