@import '~common/colors';

.scoreContainer {
  text-align: right;
}

.scoreInput input {
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-width: 15px;
  margin-left: 10px;

  &:disabled { background-color: #efefef; }
}

.saveStatus {
  margin-top: 2px;
  display: block;
  white-space: nowrap;
}

.error {
  color: red;
  margin-top: 5px;
}

.tooltip {
  color: $dark-grey;
}

.creditButton {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 15px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
}

.scoreFieldsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
