@import '~assets/stylesheets/abstracts/variables';

div#froala-editor-toolbar {
  .fr-box.fr-basic {
    .fr-wrapper {
      overflow: hidden !important;
    }

    .fr-element {
      font-family: $font-family-sans-serif !important;
      color: #555 !important;
      font-style: normal !important;
      overflow-wrap: break-word !important;
      padding: 0 !important;
      line-height: unset !important;

      p {
        margin-bottom: .5em !important;;
      }
    }
  }

  .fr-toolbar {
    border: none !important;

    .fr-newline {
      display: none !important;
    }
  }
}
