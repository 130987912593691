.image-type-selectors-wrapper {
  padding: 1em;
  display: flex;

  label {
    margin-right: 2em;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 2em;

    input {
      margin-right: 1em;
    }
  }
}