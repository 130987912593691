.notifications {
  position: relative;
}

button.helpButton {
  padding: 0;
  margin-top: 4px;

  img {
    height: 20px;
  }

  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
    transition: none;
  }
}

.dropdownHelpButton {
  position: absolute;
  top: 32px !important;
  right: 0;
  width: 240px;
  border: 1px solid lightgrey;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 #f7f7f7;
  padding: 8px 0;

  a,
  button {
    display: block;
    width: 100%;
    float: none;
    padding: 0 20px;
    text-decoration: none;
    position: relative;
    background-color: transparent !important;

    span {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      left: 20px;
      width: 200px;
      height: 24px;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      transition: background-color 0.2s;
      z-index: -1;
    }

    &:hover::before {
      background-color: #f5f5f5;
    }
  }
}

.dropdownHelpButton::before {
  position: absolute;
  top: -17px;
  right: 0;
  content: "";
  border: 9px solid transparent;
  border-bottom-color: #f7f7f7;
}
