.container {
  display: flex;
  gap: 2px;
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  padding-left: 1em;

  .tab {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    color: #777770;
    min-width: 120px;
    height: 40px;
    cursor: pointer;
    font-family: Lato, Helvetica, Arial, sans-serif;
    white-space: nowrap;
  }
}
