@import '~common/colors';

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.excludeLabelContainer {
  margin-left: auto;
}

.redBox {
  display: inline-block;
  background-color: $red;
  width: 10px;
  height: 10px;
}

.redRow td:first-child {
  border-left: 2px solid $red;
}

.warningIcon {
  height: 0.8em;
  filter: grayscale(100%);
}

