.header {
  display: flex;
  margin-bottom: 5px;
}

.title {
  padding-bottom: 4px;
}

.field {
  margin-right: 20px;
}

.districtCourseCodeField {
  width: 200px;
}

.gradeLabel {
  display: initial;
}

.gradeField {
  width: 80px;
}

.programNameField {
  width: 280px;
}

.programCodeField {
  width: 160px;
}
