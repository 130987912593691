@import 'app/frontend/javascripts/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/ElementInsert/ElementInsert.module';

.popup {
  @extend .slidePopup;
  @extend .video;

  display: unset;

  &.closed {
    display: none;
  }
}

.closeButton {
  @extend .slidePopupCloseBtn;
}
