.info {
  width: 478px;
}

.icon {
  font-size: 35px;
  margin-right: 10px;
  color: #237f99;
}

.content {
  display: flex;
  align-items: center;
}

.text {
  margin-bottom: 0;
}
