@import '~common/colors';

.modelSelect {
  width: 450px;

  label {
    margin-bottom: -4px;
  }
}

.select {
  .child-option {
    padding-left: 30px;
    font-weight: normal;
  }
}
