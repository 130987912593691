@import '~common/colors';
@import '~assets/stylesheets/pdfkit/slide_builder_modules/slide.scss';

.transitionSlide {
  display: flex;

  .leftPanel {
    display: flex;
    flex-direction: column;
    flex: 1 1 33.3%;
    background-color: $tci-purple;

    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      width: 75%;
      margin-top: 50%;
      color: white;

      .letsStart {
        font-style: italic;
        font-size: 32px;
        margin-bottom: 15px;
      }

      .slideNavInstructions {
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
      }

      .title {
        text-align: center;
        margin-top: 10px;
        font-style: italic;
        font-weight: bold;
      }

      .partyIcon {
        margin-bottom: 15px;
      }
    }

    .lessonImageTitle {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;

      .lessonImage {
        max-height: 60px;
        max-width: 60px;
        clip-path: circle(16px at 50% 50%);
        margin-left: 10px;
        object-fit: contain;
      }

      .lessonTitle {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: white;
        font-size: 0.75em;

        span {
          margin-bottom: 5px;
          padding-right: 15px;
        }
      }
    }
  }

  .rightPanel {
    flex: 2 2 66.7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 25px;

    .titleLinks {
      width: 100%;
      margin: auto;

      .progressCircle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid $tci-purple;
        background-color: white;
        z-index: 1;

        &.completed {
          background-color: $tci-purple;
        }
      }

      .progressBar {
        position: relative;
        border-left: 2px solid $tci-purple;
        left: -8px;
        top: 18px;
        height: 94%;
      }

      .extensionBar {
        border-left: 2px solid $tci-purple;
        width: 12.5px;
        height: 15px;
        position: relative;
        left: 5.5px;
      }

      .jumpToLabel {
        margin-bottom: 20px;
        margin-left: 10px;
      }
    }

    .titleLink {
      display: flex;
      width: 90%;
      height: 25px;
      align-items: center;

      .extensionTitle {
        padding-left: 25px;
        font-weight: 400;
        font-size: 11px;
      }

      .linkBorder {
        display: flex;
        align-items: center;
        margin-left: 10px;
        width: 100%;
        height: 100%;
      }

      .bottomBorder {
        border-bottom: 1px dotted darkgray;
      }

      button, a {
        display: flex;

        .pacing {
          color: #777770;
        }
      }

      img {
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
        margin-left: 5px;
      }
    }

    .actionsContainer {
      display: flex;
      gap: 16px;
      position: absolute;
      right: 0;
      transform: scale(0.75);
    }

    .backToButton {
      align-self: flex-start;
      padding: .5em 1em;
    }

    .backToButton.student {
      align-self: center;
    }

    .finalSlideItem {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      min-width: 50%;

      &:first-of-type {
        margin-top: 50px;
      }

      &:nth-of-type(2) {
        margin: 50px 0 25px 0;
      }

      p {
        margin-bottom: 15px;
        font-size: 1.1em;
      }

      &.assessBox {
        position: relative;
        border: 1px solid $orange;
        margin: 1em 0;
        padding: 2em 1em;
        width: 66%;

        &::before {
          content: 'Teacher View Only';
          display: block;
          background-color: $white;
          position: absolute;
          top: -6px;
          left: 8px;
          font-size: 10px;
          padding: 0 8px;
          color: $orange;
        }
      }
    }

    .turnInButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -1em;
      margin-bottom: 1em;

      button {
        width: fit-content;
        margin-top: 15px;
      }

      p {
        font-size: 13px;
        margin-top: 10px;
      }
    }

    .stafferTurnInButtonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      .stafferTurnInButton {
        margin-top: 25px;

        button {
          width: 100px;
          height: 45px;
        }
      }
    }

    .turnInInfo {
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        margin-bottom: 35px;
      }

      .downloadButtons {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.selected {
  border: 18px solid $slideFocusActiveColor;
}

.unansweredQuestionBox {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
