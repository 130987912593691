@import '~assets/stylesheets/abstracts/variables';

.updatedSinceGraded {
  text-align: left;
  padding-left: 5px;
  padding-top: 3px;
  margin-bottom: -10px;
  color: $blue;
  font-size: 12px;
  font-weight: bold;
}
