.buttonContainer {
  position: relative;
  display: inline-block;
}

.postButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.caret {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: .25rem .75rem;
  border-left: 2px solid #22005f;
}
