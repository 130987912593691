.dropZoneWrapper {
  width: 100%;
  height: 100%;
}

.dropZone {
  background-color: #eee;
  border: 1px dashed #555;
  padding: 1rem;
  font-size: 1rem;
  cursor: move !important;
  position: relative;

  &.transparentBackgroundDragging {
    background-color: rgba(238, 238, 238, .4);
  }

  &.transparentBackgroundNotDragging {
    background: transparent;
    border: none;
  }
}

.dropZone, .previewBox {
  &.highlighted {
    background-color: lightyellow;

    &.transparentBackgroundDragging {
      background-color: rgba(238, 238, 238, .7);
    }
  }
}

.rectangle {
  border-radius: 5px;
}

.circle {
  border-radius: 50%;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2px;
  color: #222;
  text-align: center;
  resize: none;
  border: none !important;
  background-color: inherit;
  min-height: unset !important;
  max-height: 100%;
}

.draggableBox {
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.addDropZoneButton {
  margin: 0.5em 1em;
}

.resizableChild {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: move !important;
}

.previewBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dashed #555;
  padding: .25rem;
  font-weight: bold;
  font-style: italic;
  font-size: .8rem;
}
