.dropdownItem {
  width: 100%;
  cursor: pointer;
}

.label {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table {
  overflow: scroll;
  height: 100%;
}

.numberInput {
  width: 70% !important;
}
