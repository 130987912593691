.inputContainer {
  label { display: none; }
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.hint {
  font-size: 11px;
}
