@import '~common/colors';

.row {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.label {
  min-width: 160px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
  align-self: center;
}

.textInput {
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  width: 250px;
}

.fieldError {
  color: red;
  margin-top: 5px;
}

.inlineRow {
  max-width: 700px;
  display: flex;
}

.info {
  width: 478px;
}

.icon {
  font-size: 35px;
  margin-right: 10px;
  color: #237f99;
}

.content {
  display: flex;
  align-items: center;
}


.actionContainer {
  display: flex;
  flex-direction: row-reverse;
}

.buttonContainer {
  margin-left: 40px;
  margin-top: 10px;
}

.fieldAnnotation {
  margin-top: -10px;
  margin-bottom: 20px;
}

.defaultHomeSelect {
  width: 250px;
}

.myAccountDropdown {
  width: 250px;
}

.tooltipIcon {
  margin-left: 5px;
}
