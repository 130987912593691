.existingImagesContainer {
  display: flex;
  flex-flow: row wrap;
}

.imageModelCard {
  width: 160px;
  margin: 15px;
  padding: 5px;

  .thumbnail {
    display: block;
    margin: auto;
    max-width: 160px;
    max-height: 160px;
    object-fit: contain;
  }

  .selected {
    box-shadow: 0 0 0 2px #3b5998;
  }

  &:focus {
    box-shadow: 0 0 0 2px #3b5998;
    outline: none;
  }
}
