@import '~assets/stylesheets/abstracts/variables';

.vjs-menu-content {
  margin-left: 0 !important;
}

.vjs-menu-button-popup {
  .vjs-menu {
    left: auto !important;
    right: 0;
    width: 12em;

    .vjs-menu-content {
      background-color: rgba(43, 51, 63, .9);
    }

    li {
      padding: 5px;
    }
  }
}

.vjs-menu-item {
  list-style-type: none !important;
  margin-left: 0 !important;
  text-transform: capitalize !important;
}

.video-js {
  .vjs-time-control {
    line-height: 3em !important;
  }

  .vjs-descriptions-button, .vjs-subs-caps-button {
    // Remove selection highlighting
    .vjs-menu li.vjs-selected {
      color: unset;
      background-color: unset;
    }

    .vjs-menu-item-text {
      display: flex;
      justify-content: flex-start;
      padding-left: 3px;

      .checkmark-icon {
        padding: 0 4px;

        &::before {
          display: block;
          visibility: hidden;
          height: 10px;
          width: 10px;
          content: url('reading_challenge/check-solid.svg');
        }
      }
    }

    .vjs-selected {
      .checkmark-icon::before {
        visibility: visible;
      }
    }
  }

  .vjs-descriptions-button {
    .vjs-menu-item-text {

      // Override Audio Descriptions icon
      .audio-descriptions-icon {
        padding-left: 5px;
        display: flex;
        align-items: flex-end;

        &::before {
          height: 15px;
          width: 15px;
          content: url('./audio-descriptions.svg');
        }
      }
    }

    .vjs-icon-placeholder::before {
      content: '' !important;
      background-size: 15px 15px;
      height: 15px;
      width: 15px;
      position: absolute;
      transform: translate(-50%, 50%);
      left: 50%;
      right: 50%;
      background-image: url('./audio-descriptions.svg');
    }
  }

  .vjs-texttrack-settings, .tci-custom-audio-descriptions-heading {
    border-bottom: 1px solid $dark-grey;
    display: flex;
    justify-content: center;
  }

  li.vjs-menu-item.tci-custom-audio-descriptions-heading {
    cursor: default;

    &:hover {
      background: unset;
      color: unset;
    }
  }

  .vjs-big-play-button {
    .vjs-icon-placeholder::before {
      position: unset;
    }
  }
}
