.benchmarksLink {
  color: inherit;
  font-weight: 600;
}

.tag {
  color: inherit;
}

.seeMore {
  color: #522e91;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}
