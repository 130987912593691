div.classroomCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.warningContainer {
  display: flex;
  align-items: center;

  i {
    margin-right: 5px;
    font-size: 18px;
    color: #f69c08;
  }
}

.modalFooter {
  position: absolute;
  width: 95%;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #fff;
}
