.bankContainer {
  display: flex;
  flex-flow: column;
  background-color: #eeeeee;
  flex: 1;
}

.horizontalBankContainer {
  height: 33%;
  flex-direction: column;
  overflow: auto;
}

.draggableChoicesContainer {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  height: 100%;
  border: 1px dashed #555;
  overflow: auto;

  &.highlighted {
    background-color: lightyellow;
  }
}

.horizontalDraggableChoicesContainer {
  margin-bottom: 0;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
}

.submitBtnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.floating {
    position: absolute;
    left: 1.5rem;
    bottom: .5rem;
    margin: 0;
    flex-direction: row-reverse;
    gap: .5rem;
  }
}

.draggableChoicesContainer, .submitBtnContainer {
  margin: 10px;
}

.questionInfo {
  display: flex;
  flex-direction: column;

  .questionBody {
    display: flex;
    margin: 10px;
    justify-content: space-between;

    label {
      margin-right: 5px;
    }
  }

  .answerKeyButton {
    align-self: flex-end;
    margin-right: 10px;
  }
}

.answerKeyModal {
  position: absolute;

  &Header {
    background-color: inherit;
    padding: 0;

    button {
      top: 20px !important;
      z-index: 1;
    }
  }
}

.thumbnailStyle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 100%;
  width: 100%;
}
