.inputContainer {
  display: flex;
}

.clearButton {
  cursor: pointer;
  border: none;
  padding: 0 5px;
  background: none;
}
