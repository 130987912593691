.card {
  padding: 20px;
  margin: 10px;
  font-size: 15px;

  .header {
    margin-bottom: 15px;
  }

  .lesson {
    margin: 20px 0 0 20px;

    span {
      font-weight: 600;
    }

    li {
      margin-top: 5px;
      margin-left: 20px;
    }
  }
}

.disabledLink {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.8)
}
