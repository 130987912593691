.hotspot-editor {
  .fr-toolbar {
    border: none;
    border-radius: 0px;
  }

  .fr-wrapper {
    border: none !important;
    border-radius: 0px !important;
  }

  .fr-element {
    border: none;
    border-radius: 0px;
  }
}
