@import '~common/colors';

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.choices {
  display: flex;
  justify-content: space-evenly;
}

.image {
  vertical-align: middle;
  align-items: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.image.sftpImage {
  width: 50px;
}

.radio {
  position: absolute;
  top: 10px;
  right: 10px;
}

.radioContainer {
  position: relative;
  width: 20%;
  border: 1px solid $dark-grey;
  border-radius: .25rem;
  text-align: center;
  margin: 1em 0;
}

.radioContainer::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.radioLabel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.stepSubSection {
  font-size: 16px;
  font-weight: bold;

  i:global(.fa-check-circle) { color: $green }
  i:global(.fa-exclamation-circle) { color: $orange }
}
