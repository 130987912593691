@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.container {
  position: relative;
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid $med-grey;
  line-height: 32px;
  font-size: 1em;
  margin-right: 5px;
  padding: 0 10px;
  color: #333;
  font-family: $font-family-sans-serif;
}

.toggle.expanded {
  border-radius: 4px 4px 0 0;
}

.toggle.disabled {
  background-color: #ebebe4;
  cursor: not-allowed;
}

.toggle.error {
  border-color: red;
}

.dropdown {
  box-sizing: border-box;
  width: 200px;
  max-height: 200px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  border: 1px solid $med-grey;
  line-height: 1.25rem;
  margin-top: -1px;
  overflow-y: auto;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-family: $font-family-sans-serif;
}

.caret {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  color: #999;
  width: 10px;

  &:hover {
    color: #333;
  }
}
