@import '~common/colors';

.titleImage {
  height: 1em;
}

.percent {
  font-size: 30px;
  margin-bottom: 0;
}

.header {
  display: flex;
  align-items: center;
}

.progressBar {
  margin-left: 40px;
}

.infoText {
  margin-left: 10px;
}

.steps {
  display: flex;
  flex-direction: column;
  margin: 25px 25px 25px 0;
}

.step {
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  align-items: center;
}

.headerImage {
  height: 20px;
  width: 20px;
}

.stepImage {
  height: 50px;
  width: 50px;
}

.dottedLine {
  height: 20px;
  border-left: 3px dotted $med-grey;
  align-items: center;
}

.headerLeft {
  flex-basis: 90%;
}
