.container {
  font-weight: normal !important;
  display: inline-block !important;

  span {
    width: 350px;
    display: inline-block;
    vertical-align: middle;
  }

  div {
    vertical-align: middle;
  }
}
