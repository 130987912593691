.unit {
  margin: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  figure {
    width: 7em;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }

  img {
    height: 7em;
    width: 100%;
  }

  p {
    line-height: 1.2;
    margin-top: 0.25em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

}

.unitProjectLinks {
  display: flex;
  margin: 15px;
}

.unitTitle {
 font-size: 18px;
}

.sectionHeader {
  font-weight: bolder;
  margin-top: 8px;
}

.centerBtn {
  display: flex;
  justify-content: center;
}

.centerColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}
