$height: 360px;

.container {
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  .shroud {
    content: '';
    position: absolute;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100%;
    background: #000;
    z-index: 1001;
  }

  .title {
    color: inherit;
  }

  .drawer {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 1em;
    z-index: 1000;
    transition: all 300ms ease-in;

    &[aria-hidden="true"] {
      visibility: hidden;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.25em;

      .closeBtn {
        border: none;
        background: transparent;
        font-size: 1.5rem;
        padding: .25rem;
        color: inherit;
        cursor: pointer;
      }
    }

    &.bottom {
      bottom: -$height;
      left: 0;
      height: $height;

      &.open {
        bottom: 0;
      }
    }

    &.right {
      right: -200px;
      width: 200px;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.25) -4px -8px 20px;

      &.open {
        right: 0;
      }
    }

    .content {
      height: 90%;
      overflow-y: auto;
    }
  }
}
