$animation-speed: .2s;
$lighter-gray: #c4c4c4;
$darker-gray: #555;
$box-shadow: 0 0 0 $lighter-gray;
$border: 1px solid $lighter-gray;

@mixin base-properties {
  animation: $animation-speed;
  background: $lighter-gray;
  box-shadow: $box-shadow;
  border: $border;
}

@mixin base-thumb-properties {
  box-shadow: 0 0 0 #000;
  border: 0 solid #000;
  background: $darker-gray;
}

.form {
  padding: .5rem .5rem;

  &.minWidth {
    min-width: 180px;
    padding: .5rem 1.5rem;
  }

  label {
    margin-bottom: 4px;
    margin-left: 2px;
  }
}

.rangeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;

  &.vertical {
    flex-direction: column;
  }
}

.rangeInput {
  width: 75%;
  -webkit-appearance: none;

  &.verticalInput {
    transform: rotate(90deg) translate(-58%, -2%);
    height: 128px;
    transform-origin: left;
    background: transparent;
  }

  &::-webkit-slider-runnable-track {
    @include base-properties;

    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 50px;
  }

  &::-webkit-slider-thumb {
    background: $darker-gray;
    height: 13px;
    width: 13px;
    margin-top: -6px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &::-moz-range-track {
    @include base-properties;
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 50px;
  }

  &::-moz-range-thumb {
    @include base-thumb-properties;

    height: 13px;
    width: 13px;
    border-radius: 50px;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: $animation-speed;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include base-properties;

    border-radius: 50px;
  }

  &::-ms-fill-upper {
    @include base-properties;

    border-radius: 50px;
  }

  &::-ms-thumb {
    @include base-thumb-properties;

    margin-top: 1px;
    height: 13px;
    width: 13px;
    border-radius: 50px;
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: $lighter-gray;
  }

  &:focus::-ms-fill-upper {
    background: $lighter-gray;
  }
}

.rangeValueInputWrapper {
  position: relative;

  &::after {
    content: '%';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
}

.rangeValueInput {
  // reset input styles
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  // custom input styles
  box-sizing: border-box;
  width: 60px;
  height: 35px;
  border-radius: 10px;
  border: 2px solid #d4d4d4;
  position: relative;
  text-align: center;
}
