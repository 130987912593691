.connectionData {
  display: flex;
  align-items: center;
  font-size: 16px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
  }
}

.displayName {
  font-weight: bold;
  margin-bottom: 2px;
}

.disconnect {
  img {
    height: .8em;
    margin-right: 5px;
  }

  &:hover {
    img {
      filter: brightness(100);
    }
  }
}
