@import '~assets/stylesheets/abstracts/variables';
@import '~common/colors';

$height: 50px;

.navigator {
  box-sizing: border-box;
  height: $height;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex-popover;

  .content {
    position: fixed;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    height: $height;
    padding: 0 1.5em;
    box-sizing: border-box;
    background: rgba(0, 0, 0, .8);
    bottom: 0;
    left: 0;
    transition: all 250ms ease-in;

    &.autoHide {
      bottom: -$height;
    }
  }

  &:hover {
    .content {
      &.autoHide {
        bottom: 0;
      }
    }
  }

  .navigation {
    display: -webkit-box;
  }

  .navigatorDropdownButton {
    border: none;
    background: none;
    padding: 0;
  }

  .navigatorButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    border: none;
    background: transparent;
    color: $white;
    cursor: pointer;
    padding: .5em 1em;

    &:disabled {
      opacity: .2;
      cursor: default;
    }
  }

  .dropdownHeader {
    font-weight: bold;
    font-size: 14px;
    min-width: 300px;
    padding: .25rem 1.5rem;
  }

  .dropdown {
    box-shadow: 0px 2px 15px 0px #0000004D;
    bottom: 140%;
    max-height: 360px;
    overflow-y: auto;

    .dropdownHeader {
      min-width: 175px;
    }

    .dropdownItem {
      display: flex;
      position: relative;
      padding: .25rem 1.5rem;
      min-width: 175px;

      &.active {
        font-weight: bold;
      }

      &.disabled {
        color: $med-grey;
        cursor: not-allowed;

        .lock {
          align-content: center;
          margin-left: auto;
        }

        &:hover {
          background-color: unset !important;
        }
      }
    }
  }

  .settingsDropdown {
    .dropdownHeader {
      min-width: 300px;
    }

    .dropdownItem {
      padding: .25rem 1.5rem;
      min-width: 300px;
      height: 30px;
    }

    .flexSpaceBetween {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .positionInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-size: 1.2rem;

    .greenCheck {
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      color: #2A8806;
    }

    .positionInput {
      background-color: #fff;
      width: 50px;
      height: 35px;
      font-size: 1.2rem;
      text-align: center;
      color: $very-dark-grey;
      border: 1px solid $dark-grey;
      border-radius: 4px;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      -moz-appearance: textfield;
    }
  }

  .fullScreenButton, .settingsButton {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .fullScreenButton {
    color: $tci-purple;
    padding: 0.5em;
  }

  .settingsIcon {
    padding: 0.5em;
  }

  .volumeIcon {
    width: 7px;
    display: flex;
    justify-content: center;
  }

  .editButton {
    text-align: center;
    cursor: pointer;

    i {
      border: none;
      color: $tci-purple;
      font-size: 19px;
    }
  }

  .backButton {
    justify-self: flex-start;

    img {
      width: 30px;
      height: auto;
    }

    &.disabled {
      color: $med-grey;

      a {
        pointer-events: none;
      }

      &:hover {
        color: $med-grey;
        cursor: default;
      }

      img {
        opacity: 0.5;
      }
    }
  }
}
