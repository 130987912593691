@import '~common/colors';

.flexCenter {
  display: flex;
  align-items: center;
}

.emailTextarea {
  width: 450px;
  border-radius: 4px;
  border: 1px solid $med-grey;
  height: 120px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1em;
}
