@import "~common/colors";

.textarea {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  resize: vertical !important;
  font-size: 14px;
  font-family: Lato, Helvetica, Arial, sans-serif;

  &::placeholder {
    color: #a8a8a8;
  }
}

.studentView {
  display: flex;
  align-items: center;

  .commentContainer {
    margin-left: 20px;
    flex-grow: 1;
  }
}

.newTextarea {
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  font-family: Lato, Helvetica, Arial, sans-serif;
  padding: 5px 6px;
  min-height: 30px;
}

.textareaWithStamp {
  height: auto !important;
}

.addCommentButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 7px;
  float: right;

  &:hover { text-decoration: underline; }
}

.teacherName {
  font-size: 12px;
  color: $dark-grey;
  margin-top: 2px;
}

.commentFields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
