@import '~common/colors';

.header {
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

.headerImage {
  height: 1em;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.activeDistrict {
  color: $green;
}

.inactiveDistrict {
  color: $red;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
