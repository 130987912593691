.slide.present {
  .video-js {
    .vjs-text-track-cue {
      width: 100% !important;
      font-size: 20px !important;
      inset: unset !important;
      bottom: 2em !important;
    }

    .vjs-text-track-display {
      position: absolute;
      inset: 0;
      // width: 100%;
      // height: 100%;
      display: flex;
      flex-direction: column;

      >div {
        padding-top: 56.25%;
        inset: unset !important;
        position: relative !important;
        width: 100%;
        margin: 0 !important;
      }
    }

    .vjs-text-track-cue {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    &.vjs-fullscreen {
      .vjs-text-track-display {
        position: relative;
        height: 100%;
        width: 100%;
        justify-content: center;
      }

      .vjs-text-track-cue {
        font-size: 40px !important;
        bottom: 1em !important;
      }
    }

    &.vjs-user-inactive.vjs-playing {
      .vjs-text-track-cue {
        bottom: 1em !important;
      }

      &.vjs-fullscreen {
        .vjs-text-track-cue {
          bottom: 0.5em !important;
        }
      }
    }
  }
}

.video-js .vjs-control-bar {
  transform: translateZ(0);
}
