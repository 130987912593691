.submitBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 0 1em;
}

.incorrectIconColor {
  color: #f69c05;
}

.respectWhiteSpace {
  white-space: pre-wrap;
}

.stimuliWrapper {
  display: flex;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
