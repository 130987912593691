@import '~common/colors';

.modal {
  width: 900px;

  p, ul {
    line-height: 120%;
  }

  li {
    padding: 5px 0;
  }
}

.selectionColumns {
  display: grid;
  grid-template-columns: 1fr .5fr 1.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 10px;
  margin-top: 30px;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  img {
    margin-right: 10px;
  }
}

.middle {
  justify-self: center;
}

.existingMatch {
  padding-right: 30px;
  display: flex;
  align-items: center;
}

.name {
  flex-grow: 1;
}

.warning {
  margin-right: 5px;
  color: $orange;
  font-size: 20px;
}
