@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.imageInputContainer {
  position: relative;
  padding-left: 0;

  .imageControls {
    padding: 5px 0;
  }

  &.noImage::before {
    content: 'No Image Selected';
    white-space: nowrap;
    position: absolute;
    top: 47px;
    left: -5px;
    transform: rotate(-45deg);
    pointer-events: none;
  }

  input { display: none; }

  img {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid $med-grey;

    &.noImage {
      width: 100px;
      height: 100px;
    }
  }
}

.formRow {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0;
}

.mainLabel {
  pointer-events: none;
}
