.headerButton {
  &:hover {
    color: #fff !important;
    background-color: #522e91 !important;
    border-color: #522e91 !important;

    img {
      filter: invert(100%);
      transition: all 0.5s;
    }
  }

  .buttonContent {
    display: flex;

    img {
      margin-right: 5px;
    }
  }
}
