.modalBodyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gridAnswerTableWrapper {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .languageToggleWithTable {
    .tableLinks {
      display: flex;
      justify-content: space-between;

      .languageTabs {
        padding: 10px 5px;
      }
    }

    .saveButton {
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
      margin-top: 10px;
    }
  }
}

.gridAnswerTableNotebookGridWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 5px;
  background-color: white;
}

.hidden {
  display: none;
}
