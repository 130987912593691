@import '~assets/stylesheets/abstracts/variables';

.thumbnailWrapper {
  display: flex;
  position: relative;
  width: 200px;
  height: 100px;

  .positionNumber {
    margin: 5px 0 0 5px;
    min-width: 20px;
    height: 20px;
    text-align: center;
  }

  .overflowHidden {
    div > * {
      overflow: hidden;
    }
  }

  .adjusted {
    margin-top: -20px;
    margin-left: 1px;
  }

  .noPointerEvents {
    pointer-events: none;
  }

  &.pointer {
    cursor: pointer;
  }

  &:hover {
    :global(.slide) {
      border: 18px solid $slideFocusActiveColor
    }
  }

  &.selected {
    background: #{$slideFocusActiveColor}66; // 40% opacity
  }

  &:focus {
    outline: none;
  }
}

.slide {
  display: flex;
  margin-bottom: 10px;
  padding: 5px 0;

  .positionNumber {
    margin-right: 10px;
    height: 17px;
    width: 17px;
    text-align: right;
  }

  .thumbnail {
    width: 160px;
    min-width: 160px;
    height: 90px;
    background: $white;
    border: none;
    box-sizing: border-box;
    position: relative;

    &:hover {
      border: 3px solid $slideFocusActiveColor;
    }
  }
}

.addSlide {
  display: flex;

  &:hover {
    cursor: pointer;
  }

  .plus {
    margin: auto;
    font-size: 35px;
  }
}
