.formRow {
  display: flex;
  margin-bottom: 25px;
}

.label {
  width: 130px;
  font-weight: bold;
  overflow: visible;
}

.userContainer {
  display: flex;
}

.fileName {
  padding: 5px;
  width: 85%;
}

.googleIcon {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.rightItems {
  padding: 2px 0 0 10px;

  div {
    align-items: center;
    margin-bottom: 6px;

    &.name {
      font-weight: bold;
    }
  }
}

.googleDrive {
  min-height: 300px;

  i {
    top: 30% !important;
    left: 45% !important;
  }
}

.switchAccounts {
  padding: 0 0 2px 3px;
}

.warningBox {
  background-color: #fff8e1;
  border: 2px solid #ffdfba;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;

  i {
    color: #f69c08;
    font-size: 24px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.warningText {
  font-size: 14px;
  color: #555555;
}
