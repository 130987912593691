@import '~common/colors';

.button {
  color: white;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin-left: -10px;
  cursor: pointer;
  font-size: 16px;

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
}

.check  {
  color: $green;
}

.option:active {
  background-color: $green !important;
}

.notSelected {
  padding-left: 2.75rem !important;
}

.copyrightLight {
  color: #b6b2d9;
}

.copyrightDark {
  color: #5a5582;
}

.bottomContainer {
  width: inherit;
  background-color: $tci-purple;
  border-top: solid 1px #6a4e9c;
}

.hidden {
  display: none;
}

.dropdownMenu {
  transform: translate3d(0px, -78px, 0px) !important;
}
