.card {
  border: 1px solid $light-grey;
  border-radius: $border-radius;
  box-shadow: $card-box-shadow;
  transition: all .3s;
}

.card:hover {
  box-shadow: $card-hover-box-shadow;
}

.card.disabled:hover {
  box-shadow: $card-box-shadow;
}
