@import '~common/colors';

.textarea {
  width: 70%;
  margin-top: 10px;
  min-height: 70px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #777770;
  font-family: Lato, Helvetica, Arial, clean, sans-serif;
  font-size: .875rem;
  line-height: 1rem;
  color: #555;
}

.error {
  background-color: #fdd0cd;
}

.questionImage {
  max-width: 100%;
  padding-top: 10px;
}

.questionAnswer {
  padding-left: 43px;
}

.label {
  margin-left: 5px;
  display: initial;
}

.invalid {
  .textarea {
    outline: none;
    border: 2px solid $red;
    border-radius: 4px;
  }
}

.invalidStatusMessage {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0 0 0 10px;
  color: $red;
  text-align: left;

  i {
    color: $red;
    margin-right: 15px;
  }
}
