.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1.5em;
  padding-top: 12px;
  border-top: 1px solid #ccc;
}

.gameTemplateSelectWrapper {
  max-width: 450px;
}

.vocabLabel {
  font-weight: bold;
  margin-bottom: 1em;
}

.checkboxesWrapper {
  margin-left: 1.5em;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.sectionTitle {
  font-style: italic;
}
