@import '~common/colors';
@import '~assets/stylesheets/abstracts/variables';

.titleSection {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: $white;
  border: none;
  font-family: $font-family-base;
  color: $very-dark-grey;
}

.programTitleSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 100px;
  width: 100%;
  background-color: $white;
  border: none;
  font-family: $font-family-base;
  color: $very-dark-grey;
}

.programTitle {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.title {
  margin: auto 0;
  font-size: 16px;
  font-weight: bold;
}

.expanded {
  color: $tci-purple;
}

.arrow {
  font-size: 1.8em;
  padding: 12px;
  display: block;
  position: relative;
  margin-right: 20px;
  background-color: $white;
  border: none;
}

.collapsedSection {
  hr {
    margin: 0;
  }

  &.collapsed { display: none; }
}

.collapsedText {
  font-size: 14px;
  padding: 15px 15px 0;
  line-height: 130%;
}

.content {
  color: $dark-grey;
  padding: 10px 15px;
}

.image {
  max-width: 75px;
  max-height: 75px;
  margin-right: 32px;
}
