.acsLabel {
  margin-right: 20px !important;
}

.entityLabel {
  margin-right: 9px !important;
}

.ssoLabel {
  margin-right: 21px !important;
}

.samlLabels {
  width: 192px;
}

.textarea {
  height: 250px;
}

.textInput {
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: #333;
  font-size: 14px;
  height: 25px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 60%;
}
