.imageChoicesContainer {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 32px 0;

  .imageChoice {
    flex-basis: calc(50% - 20px);  /* width minus margins */
    margin: 0 0 40px;

    .imageChoiceWrapper {
      display: flex;
      flex-direction: column;
      padding: 10px 0 10px 5px;
      width: 85%;

      .optionContainer {
        display: flex;
        margin-bottom: 5px;
      }

      .optionLabel {
        margin-right: 1px;
      }
    }

    .imageChoiceBorder {
      box-shadow: 0 0 0 2px #286ee6 inset;
    }

    label {
      margin-right: 3px;
      margin-top: 1px;
      display: flex;
      justify-content: center;
    }

    li {
      display: flex;
      margin-top: 2px;
    }

    img {
      width: 180px;
      height: 155px;
      object-fit: contain
    }
  }
}

