@import '~assets/stylesheets/abstracts/variables';

.banner {
  width: 100%;
  display: flex;
  overflow: hidden;
  top: 0;
  margin: 15px 0;
  z-index: 1000;
  background-color: white;
  border: none;
  line-height: 25px;
}

.lockIcon {
  flex-basis: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 24px;
  padding: 15px 0px 15px 15px;
}

.content {
  flex-grow: 1;
  padding: 10px;
}

.blue {
  background-color: $blue;
}

.green {
  background-color: $green;
}

.yellow {
  background-color: #fff8e1;
}

.title {
  font-weight: bold;
}
